/* -------------
NEW
MÅ TRYKKE PÅ FOR Å FÅ EFFEKTINFO (FINERE VISUELT)

--------------- */

import React, { useEffect, useState, useRef } from 'react';
import defaultModelImage from '../assets/modelimage.png';
import defaultDynoImage from '../assets/dynoimage.png';

import { fetchVehicleModels } from './Database';
/* AHB */

export async function fetchVehicleModelByDetails({ manufacturerName, modelName, power }) {
  const queryParams = new URLSearchParams({
    manufacturerName,
    modelName,
    power,
  });

  const url = `${process.env.REACT_APP_SITE_URL}/vehicle-models?${queryParams.toString()}`;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch vehicle model details');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching vehicle model details:', error);
    return null;
  }
}

function UploadDynoImageComponent({ modelId, onUploadSuccess }) {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('dynoImage', file);
    formData.append('modelId', modelId);

    fetch(process.env.REACT_APP_SITE_URL + '/uploadDynoImage', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        alert('Dyno image uploaded successfully: ' + data.imageUrl);
        onUploadSuccess(data.imageUrl);  // Callback to update the parent state
      })
      .catch(error => {
        console.error('Error uploading dyno image:', error);
      });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      <button onClick={triggerFileInput} className="upload-button">Upload Dyno Image</button>
    </div>
  );
}

function UploadImageComponent({ modelId, onUploadSuccess }) {
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('modelImage', file);
    formData.append('modelId', modelId);

    fetch(process.env.REACT_APP_SITE_URL + '/uploadModelImage', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        alert('Image uploaded successfully: ' + data.imageUrl);
        onUploadSuccess(data.imageUrl);  // Callback to update the parent state
      })
      .catch(error => {
        console.error('Error uploading image:', error);
      });
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
      <button onClick={triggerFileInput} className="upload-button">Upload Image</button>
    </div>
  );
}

function Database({ onClose, registrationNumber }) {
  const modalRef = useRef(null);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editData, setEditData] = useState({
    power_opti: '',
    torque_opti: '',
    power: '',
    torque: '',
    obd_location: '',
    ecu_location: '',
    notes: ''
  });
  const [selectedModelId, setSelectedModelId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [viewImage, setViewImage] = useState(null);
  const [copiedOpti, setCopiedOpti] = useState(false);
  const [copiedPower, setCopiedPower] = useState(false);
  const [selectedFuelType, setSelectedFuelType] = useState(null);
  const [country, setCountry] = useState(''); // Add country state

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const fetchVehicleModels = () => {
    fetch(process.env.REACT_APP_SITE_URL + '/vehicle-models')
      .then(response => response.json())
      .then(data => {
        setVehicleModels(data);
      })
      .catch(error => console.error('Error fetching vehicle models:', error));
  };
 /* ahb end*/
  const fetchVehicleData = async () => {
    if (registrationNumber) {
      const data = await fetchVehicleModels(registrationNumber);
      setVehicleModels(data);
    }
  };

  useEffect(() => {
    fetchVehicleData();
  }, [registrationNumber]);

  /* ahb end*/

  const toggleRow = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const openImage = (imageUrl) => {
    setViewImage(imageUrl);
  };

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === 'opti') {
        setCopiedOpti(true);
        setTimeout(() => setCopiedOpti(false), 2500);
      } else if (type === 'power') {
        setCopiedPower(true);
        setTimeout(() => setCopiedPower(false), 2500);
      }
    }, (err) => {
      console.error('Failed to copy text to clipboard', err);
    });
  };

  const handleEdit = (model) => {
    setSelectedModelId(model.id);
    setSelectedFuelType(model.fueltype);
    setEditData({
      power_opti: model.power_opti,
      torque_opti: model.torque_opti,
      power: model.power,
      torque: model.torque,
      obd_location: model.obd_location,
      ecu_location: model.ecu_location,
      notes: model.notes
    });
    setShowEditForm(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData(prev => ({ ...prev, [name]: value }));
  };

  const saveEdit = () => {
    fetch(process.env.REACT_APP_SITE_URL + '/updateModel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: selectedModelId,
        ...editData
      })
    })
      .then(response => response.json())
      .then(data => {
        setShowEditForm(false);
        fetchVehicleModels(); // Fetch updated models after edit
      })
      .catch(error => {
        console.error('Error updating model:', error);
      });
  };

  const cancelEdit = () => {
    setShowEditForm(false);
  };

  const filteredModels = searchText ? vehicleModels.filter(model => {
    const searchTerms = searchText.toLowerCase().split(' ').filter(term => term.trim() !== '');
    return searchTerms.every(term =>
      model.modelName?.toLowerCase().includes(term) ||
      model.manufacturerName?.toLowerCase().includes(term) ||
      model.rangeName?.toLowerCase().includes(term) ||
      model.typeName?.toLowerCase().includes(term)
    );
  }) : [];

  useEffect(() => {
    if (searchText) {
      fetchVehicleModels();
    } else {
      setVehicleModels([]);
    }
  }, [searchText]);

  const renderCountryText = () => {
    switch (country) {
      case 'NO':
        return 'Her finner du effektinfo til forskjellige steg, samt annen relevant info om flere typer kjøretøy. Databasen er i konstant utvikling. Du kan og bidra ved å foreslå informasjon til enkelte kjøretøy for å bygge databasen større. Om du ikke finner er kjøretøy, så kan du request info om dette her';
      case 'SE':
        return 'Här hittar du effektinformation för olika steg, samt annan relevant information om olika typer av fordon. Databasen är i ständig utveckling. Du kan också bidra genom att föreslå information om vissa fordon för att göra databasen större. Om du inte hittar din bil kan du begära information om det här';
      default:
        return 'Here you will find power information for different stages, as well as other relevant information about various types of vehicles. The database is constantly evolving. You can also contribute by suggesting information for certain vehicles to make the database bigger. If you cannot find your vehicle, you can request information about it here';
    }
  };


  return (
    <div className="app-databasemodal-background">
      <div className="app-databasemodal-content" onClick={e => e.stopPropagation()}>
        <div className="app-modal-header">
          {/* <button className="close-btn" onClick={onClose}>CLOSE</button> */}
        </div>
        {/* <h1>Vehicles Database</h1> */}
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          className="app-search-input"
        />
        <div className="app-search-results">
          {/* <div className='app-disc-table'>
          <span className="app-model-description">Vehicle</span>
          <span className="app-model-stats">
          <span className="app-disc-opti">
            <span className='app-show-opti'>
              OPTI
          </span>
          <span className="app-disc-power">
            <span className='app-show-power'>POWER
            </span>

          </span>
          </span>
          </span>
          </div> */}

          {filteredModels.length > 0 ? (
            filteredModels.map((model, index) => (
              <div className='app-databasemodal-nowrap'>
              <div key={index}>
                <div className='app-veh-table'>
                <div className={`app-model-entry ${index % 2 === 0 ? 'even' : 'odd'}`} onClick={() => toggleRow(index)}>
                  <span className="app-model-description">{`${model.manufacturerName} ${model.rangeName} ${model.modelName}`}</span>
                  {/* <span className="app-model-stats">
                    {model.fueltype !== 3 && (
                      <span className="app-model-opti"> <b>{model.power_opti} Hp / {model.torque_opti} Nm</b></span>
                    )}
                    <span className="app-model-power"> <b>{model.power} Hp / {model.torque} Nm</b></span>
                  </span> */}
                </div>
                </div>
                {activeIndex === index && (
                  <div>
                    {/* <div className="app-model-name-display">
                      <h2>{model.modelName}</h2>
                    </div> */}
                    <div className="app-text-details">
                        <p className='app-disc-opti'>OPTI:<br></br> {model.power_opti} Hp / {model.torque_opti} Nm  <br></br><button className={copiedOpti ? 'copied' : ''} onClick={() => copyToClipboard(`${model.power_opti} Hp / ${model.torque_opti} Nm`, 'opti')}>{copiedOpti ? 'Copied!' : 'Copy'}</button></p>
                        <p className="app-disc-power">POWER: <br></br>{model.power} Hp / {model.torque} Nm <br></br><button className={copiedPower ? 'copied' : ''} onClick={() => copyToClipboard(`${model.power} Hp / ${model.torque} Nm`, 'power')}>{copiedPower ? 'Copied!' : 'Copy'}</button></p>
                        <p><text className='app-txt-bold'> OBD Location: </text><br></br>PLZ HOLDER UNDER STEERINGWHEEL{model.obd_location}</p>
                        <p><text className='app-txt-bold'>ECU Location:</text> <br></br>PLZ HOLDER UNDER BONNET{model.ecu_location}</p>
                        <p><text className='app-txt-bold'>Notes:</text> <br></br>test{model.notes}</p>
                        <div className="app-button-group">
                          <UploadImageComponent modelId={model.id} onUploadSuccess={(imageUrl) => {
                            const updatedModels = vehicleModels.map(m => {
                              if (m.id === model.id) {
                                return { ...m, modelimage: imageUrl };
                              }
                              return m;
                            });
                            setVehicleModels(updatedModels);
                          }} />
                          <button onClick={() => handleEdit(model)}>Edit Info</button>
                          {/* <UploadDynoImageComponent modelId={model.id} onUploadSuccess={(imageUrl) => {
                            const updatedModels = vehicleModels.map(m => {
                              if (m.id === model.id) {
                                return { ...m, dynoimage: imageUrl };
                              }
                              return m;
                            });
                            setVehicleModels(updatedModels);
                          }} /> */}
                        </div>
                      </div>

                      </div>


                )}

                </div>

                </div>



            ))

          ) : (
            <div className="ooh-contentholder">{renderCountryText()}</div>
          )}

        </div>
        {viewImage && (
          <div className="app-image-modal" onClick={() => setViewImage(null)}>
            <img src={viewImage} alt="Full Size" />
          </div>
        )}
        {showEditForm && (
          <div className="app-edit-modal" ref={modalRef}>
            <h3>Edit Model Info</h3>
            {selectedFuelType !== 3 && (
              <>
                <label>
                  Opti HP:
                  <input type="text" name="power_opti" value={editData.power_opti} onChange={handleEditChange} />
                </label><br />
                <label>
                  Opti NM:
                  <input type="text" name="torque_opti" value={editData.torque_opti} onChange={handleEditChange} />
                </label><br />
              </>
            )}
            <label>
              Power HP:
              <input type="text" name="power" value={editData.power} onChange={handleEditChange} />
            </label><br />
            <label>
              Power NM:
              <input type="text" name="torque" value={editData.torque} onChange={handleEditChange} />
            </label><br />
            <label>
              OBD Location:
              <input type="text" name="obd_location" value={editData.obd_location} onChange={handleEditChange} />
            </label><br />
            <label>
              ECU Location:
              <input type="text" name="ecu_location" value={editData.ecu_location} onChange={handleEditChange} />
            </label><br />
            <label>
              Notes:
              <textarea name="notes" value={editData.notes} onChange={handleEditChange} />
            </label><br />
            <button onClick={saveEdit}>Save</button>
            <button onClick={cancelEdit}>Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Database;



/* -------------
OLD FUNGERENDE
HAR KJAPP INFO OM HK OG NM

--------------- */

// import React, { useEffect, useState, useRef } from 'react';
// import defaultModelImage from '../assets/modelimage.png';
// import defaultDynoImage from '../assets/dynoimage.png';

// function UploadDynoImageComponent({ modelId, onUploadSuccess }) {
//   const fileInputRef = useRef(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     const formData = new FormData();
//     formData.append('dynoImage', file);
//     formData.append('modelId', modelId);

//     fetch(process.env.REACT_APP_SITE_URL + '/uploadDynoImage', {
//       method: 'POST',
//       body: formData
//     })
//       .then(response => response.json())
//       .then(data => {
//         alert('Dyno image uploaded successfully: ' + data.imageUrl);
//         onUploadSuccess(data.imageUrl);  // Callback to update the parent state
//       })
//       .catch(error => {
//         console.error('Error uploading dyno image:', error);
//       });
//   };

//   const triggerFileInput = () => {
//     fileInputRef.current.click();
//   };

//   return (
//     <div>
//       <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
//       <button onClick={triggerFileInput} className="upload-button">Upload Dyno Image</button>
//     </div>
//   );
// }

// function UploadImageComponent({ modelId, onUploadSuccess }) {
//   const fileInputRef = useRef(null);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     const formData = new FormData();
//     formData.append('modelImage', file);
//     formData.append('modelId', modelId);

//     fetch(process.env.REACT_APP_SITE_URL + '/uploadModelImage', {
//       method: 'POST',
//       body: formData
//     })
//       .then(response => response.json())
//       .then(data => {
//         alert('Image uploaded successfully: ' + data.imageUrl);
//         onUploadSuccess(data.imageUrl);  // Callback to update the parent state
//       })
//       .catch(error => {
//         console.error('Error uploading image:', error);
//       });
//   };

//   const triggerFileInput = () => {
//     fileInputRef.current.click();
//   };

//   return (
//     <div>
//       <input type="file" ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
//       <button onClick={triggerFileInput} className="upload-button">Upload Image</button>
//     </div>
//   );
// }

// function Database({ onClose }) {
//   const modalRef = useRef(null);
//   const [vehicleModels, setVehicleModels] = useState([]);
//   const [showEditForm, setShowEditForm] = useState(false);
//   const [editData, setEditData] = useState({
//     power_opti: '',
//     torque_opti: '',
//     power: '',
//     torque: '',
//     obd_location: '',
//     ecu_location: '',
//     notes: ''
//   });
//   const [selectedModelId, setSelectedModelId] = useState(null);
//   const [searchText, setSearchText] = useState('');
//   const [activeIndex, setActiveIndex] = useState(null);
//   const [viewImage, setViewImage] = useState(null);
//   const [copiedOpti, setCopiedOpti] = useState(false);
//   const [copiedPower, setCopiedPower] = useState(false);
//   const [selectedFuelType, setSelectedFuelType] = useState(null);
//   const [country, setCountry] = useState(''); // Add country state

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === 'Escape') {
//         onClose();
//       }
//     };

//     window.addEventListener('keydown', handleKeyDown);
//     return () => {
//       window.removeEventListener('keydown', handleKeyDown);
//     };
//   }, [onClose]);

//   const fetchVehicleModels = () => {
//     fetch(process.env.REACT_APP_SITE_URL + '/vehicle-models')
//       .then(response => response.json())
//       .then(data => {
//         setVehicleModels(data);
//       })
//       .catch(error => console.error('Error fetching vehicle models:', error));
//   };

//   const toggleRow = index => {
//     setActiveIndex(activeIndex === index ? null : index);
//   };

//   const openImage = (imageUrl) => {
//     setViewImage(imageUrl);
//   };

//   const copyToClipboard = (text, type) => {
//     navigator.clipboard.writeText(text).then(() => {
//       if (type === 'opti') {
//         setCopiedOpti(true);
//         setTimeout(() => setCopiedOpti(false), 2500);
//       } else if (type === 'power') {
//         setCopiedPower(true);
//         setTimeout(() => setCopiedPower(false), 2500);
//       }
//     }, (err) => {
//       console.error('Failed to copy text to clipboard', err);
//     });
//   };

//   const handleEdit = (model) => {
//     setSelectedModelId(model.id);
//     setSelectedFuelType(model.fueltype);
//     setEditData({
//       power_opti: model.power_opti,
//       torque_opti: model.torque_opti,
//       power: model.power,
//       torque: model.torque,
//       obd_location: model.obd_location,
//       ecu_location: model.ecu_location,
//       notes: model.notes
//     });
//     setShowEditForm(true);
//   };

//   const handleEditChange = (e) => {
//     const { name, value } = e.target;
//     setEditData(prev => ({ ...prev, [name]: value }));
//   };

//   const saveEdit = () => {
//     fetch(process.env.REACT_APP_SITE_URL + '/updateModel', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         id: selectedModelId,
//         ...editData
//       })
//     })
//       .then(response => response.json())
//       .then(data => {
//         setShowEditForm(false);
//         fetchVehicleModels(); // Fetch updated models after edit
//       })
//       .catch(error => {
//         console.error('Error updating model:', error);
//       });
//   };

//   const cancelEdit = () => {
//     setShowEditForm(false);
//   };

//   const filteredModels = searchText ? vehicleModels.filter(model => {
//     const searchTerms = searchText.toLowerCase().split(' ').filter(term => term.trim() !== '');
//     return searchTerms.every(term =>
//       model.modelName?.toLowerCase().includes(term) ||
//       model.manufacturerName?.toLowerCase().includes(term) ||
//       model.rangeName?.toLowerCase().includes(term) ||
//       model.typeName?.toLowerCase().includes(term)
//     );
//   }) : [];

//   useEffect(() => {
//     if (searchText) {
//       fetchVehicleModels();
//     } else {
//       setVehicleModels([]);
//     }
//   }, [searchText]);

//   const renderCountryText = () => {
//     switch (country) {
//       case 'NO':
//         return 'Her finner du effektinfo til forskjellige steg, samt annen relevant info om flere typer kjøretøy. Databasen er i konstant utvikling. Du kan og bidra ved å foreslå informasjon til enkelte kjøretøy for å bygge databasen større. Om du ikke finner er kjøretøy, så kan du request info om dette her';
//       case 'SE':
//         return 'Här hittar du effektinformation för olika steg, samt annan relevant information om olika typer av fordon. Databasen är i ständig utveckling. Du kan också bidra genom att föreslå information om vissa fordon för att göra databasen större. Om du inte hittar din bil kan du begära information om det här';
//       default:
//         return 'Here you will find power information for different stages, as well as other relevant information about various types of vehicles. The database is constantly evolving. You can also contribute by suggesting information for certain vehicles to make the database bigger. If you cannot find your vehicle, you can request information about it here';
//     }
//   };

//   return (
//     <div className="app-databasemodal-background">
//       <div className="app-databasemodal-content" onClick={e => e.stopPropagation()}>
//         <div className="app-modal-header">
//           {/* <button className="close-btn" onClick={onClose}>CLOSE</button> */}
//         </div>
//         {/* <h1>Vehicles Database</h1> */}
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchText}
//           onChange={(e) => setSearchText(e.target.value)}
//           className="app-search-input"
//         />
//         <div className="app-search-results">
//           <div className='app-disc-table'>
//           <span className="app-model-description">Vehicle</span>
//           <span className="app-model-stats">
//           <span className="app-disc-opti">
//             <span className='app-show-opti'>
//               OPTI
//           </span>
//           <span className="app-disc-power">
//             <span className='app-show-power'>POWER
//             </span>

//           </span>
//           </span>
//           </span>
//           </div>

//           {filteredModels.length > 0 ? (
//             filteredModels.map((model, index) => (
//               <div className='app-databasemodal-nowrap'>
//               <div key={index}>
//                 <div className='app-veh-table'>
//                 <div className={`app-model-entry ${index % 2 === 0 ? 'even' : 'odd'}`} onClick={() => toggleRow(index)}>
//                   <span className="app-model-description">{`${model.manufacturerName} ${model.rangeName} ${model.modelName}`}</span>
//                   <span className="app-model-stats">
//                     {model.fueltype !== 3 && (
//                       <span className="app-model-opti"> <b>{model.power_opti} Hp / {model.torque_opti} Nm</b></span>
//                     )}
//                     <span className="app-model-power"> <b>{model.power} Hp / {model.torque} Nm</b></span>
//                   </span>
//                 </div>
//                 </div>
//                 {activeIndex === index && (
//                   <div>
//                     <div className="app-model-name-display">
//                       <h2>{model.modelName}</h2>
//                     </div>
//                     <div className="app-model-expanded-details">
//                       <img src={model.modelimage || defaultModelImage} alt="Model Image" className="app-detail-image" onClick={() => openImage(model.modelimage || defaultModelImage)} />
//                       <div className="app-text-details">
//                         <p>Opti: {model.power_opti} Hp / {model.torque_opti} Nm  <button className={copiedOpti ? 'copied' : ''} onClick={() => copyToClipboard(`${model.power_opti} Hp / ${model.torque_opti} Nm`, 'opti')}>{copiedOpti ? 'Copied!' : 'Copy'}</button></p>
//                         <p>Power: {model.power} Hp / {model.torque} Nm <button className={copiedPower ? 'copied' : ''} onClick={() => copyToClipboard(`${model.power} Hp / ${model.torque} Nm`, 'power')}>{copiedPower ? 'Copied!' : 'Copy'}</button></p>
//                         <p>OBD Location: {model.obd_location}</p>
//                         <p>ECU Location: {model.ecu_location}</p>
//                         <p>Notes: {model.notes}</p>
//                         <div className="app-button-group">
//                           <UploadImageComponent modelId={model.id} onUploadSuccess={(imageUrl) => {
//                             const updatedModels = vehicleModels.map(m => {
//                               if (m.id === model.id) {
//                                 return { ...m, modelimage: imageUrl };
//                               }
//                               return m;
//                             });
//                             setVehicleModels(updatedModels);
//                           }} />
//                           <button onClick={() => handleEdit(model)}>Edit Info</button>
//                           <UploadDynoImageComponent modelId={model.id} onUploadSuccess={(imageUrl) => {
//                             const updatedModels = vehicleModels.map(m => {
//                               if (m.id === model.id) {
//                                 return { ...m, dynoimage: imageUrl };
//                               }
//                               return m;
//                             });
//                             setVehicleModels(updatedModels);
//                           }} />
//                         </div>
//                       </div>
//                       <img src={model.dynoimage || defaultDynoImage} alt="Dyno Image" className="app-detail-image" onClick={() => openImage(model.dynoimage || defaultDynoImage)} />
//                     </div>
//                   </div>
//                 )}
//                 </div>

//                 </div>



//             ))

//           ) : (
//             <div className="ooh-contentholder">{renderCountryText()}</div>
//           )}

//         </div>
//         {viewImage && (
//           <div className="app-image-modal" onClick={() => setViewImage(null)}>
//             <img src={viewImage} alt="Full Size" />
//           </div>
//         )}
//         {showEditForm && (
//           <div className="app-edit-modal" ref={modalRef}>
//             <h3>Edit Model Info</h3>
//             {selectedFuelType !== 3 && (
//               <>
//                 <label>
//                   Opti HP:
//                   <input type="text" name="power_opti" value={editData.power_opti} onChange={handleEditChange} />
//                 </label><br />
//                 <label>
//                   Opti NM:
//                   <input type="text" name="torque_opti" value={editData.torque_opti} onChange={handleEditChange} />
//                 </label><br />
//               </>
//             )}
//             <label>
//               Power HP:
//               <input type="text" name="power" value={editData.power} onChange={handleEditChange} />
//             </label><br />
//             <label>
//               Power NM:
//               <input type="text" name="torque" value={editData.torque} onChange={handleEditChange} />
//             </label><br />
//             <label>
//               OBD Location:
//               <input type="text" name="obd_location" value={editData.obd_location} onChange={handleEditChange} />
//             </label><br />
//             <label>
//               ECU Location:
//               <input type="text" name="ecu_location" value={editData.ecu_location} onChange={handleEditChange} />
//             </label><br />
//             <label>
//               Notes:
//               <textarea name="notes" value={editData.notes} onChange={handleEditChange} />
//             </label><br />
//             <button onClick={saveEdit}>Save</button>
//             <button onClick={cancelEdit}>Cancel</button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default Database;
