import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Oppdater stien etter den faktiske plasseringen

export default function TopBar({ openDatabaseModal, openUploadModal }) {
    return (
        <nav>
            <img src={logo} alt="Logo" className="logo" />
            <ul>
                <li><Link to="/" onClick={openUploadModal}>UPLOAD</Link></li>
                <li><Link to="/" >FILEPORTAL</Link></li>
                <li><Link to="/"  onClick={openDatabaseModal}>DATABASE</Link></li>
                <li><Link to="/support" >SUPPORT</Link></li>
                <li><Link to="/opticenter" >OPTICENTER</Link></li>
            </ul>
        </nav>
    );
}
