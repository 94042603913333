import React, { useState, useEffect, useRef } from 'react';

import { fetchVehicleModelByDetails } from './Database'; // Import the fetch function from Database.js



function Vehsearch({ fileId, onClose, userProfile }) {
  const [vehicleData, setVehicleData] = useState(null);
  const [licensePlate, setLicensePlate] = useState('');
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const licensePlateInputRef = useRef(null);
  const [modelData, setModelData] = useState(null);
  const [filteredModelData, setFilteredModelData] = useState(null); // State for filtered database results

  const [additionalModelData, setAdditionalModelData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const [copiedOpti, setCopiedOpti] = useState(false);
  const [copiedPower, setCopiedPower] = useState(false);


  const fetchVehicleData = async (regNum) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
      if (!response.ok) {
        throw new Error('Failed to fetch vehicle data');
      }
      const data = await response.json();
      setVehicleData(data);

      // Extract the year from the registration date
      const registrationYear = new Date(data.kjoretoydataListe[0]?.godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato).getFullYear();

      // Convert power from kW to HP
      const kW = data.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.drivstoff[0]?.maksNettoEffekt || '';
      const hp = (kW * 1.36).toFixed(0);

      // Extract necessary details for database search
      const vehicleDetails = {
        manufacturerName: data.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke?.[0]?.merke || '',
        modelName: data.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse?.[0] || '',
        power: hp,
        registrationYear: registrationYear,
      };


      // Trigger the database search with filtered details
      fetchAndFilterVehicleModels(vehicleDetails);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
      setVehicleData(null); // Clear data on error
    }
  };

  const fetchAndFilterVehicleModels = async (details) => {
    if (details.manufacturerName && details.modelName) {
      try {
        const data = await fetchVehicleModelByDetails(details);

        // Filter data based on vehicle details
        const filteredData = data.filter(model => {
          // Ensure that all fields used in filtering are available
          const manufacturerName = model.manufacturerName?.toLowerCase() || '';
          const rangeName = model.rangeName?.toLowerCase() || '';
          const modelName = model.modelName?.toLowerCase() || '';

          const matchesManufacturer = manufacturerName === details.manufacturerName.toLowerCase();
          const matchesModel = rangeName.includes(details.modelName.toLowerCase());

          // Extract the year range and bhp from the model string
          const yearRangeMatch = modelName.match(/\((\d{2})\/(\d{4}) - (\d{2})\/(\d{4})\)/);
          const bhpMatch = modelName.match(/(\d+)-BHP/);

          if (yearRangeMatch && bhpMatch) {
            const startYear = parseInt(yearRangeMatch[2], 10);
            const endYear = parseInt(yearRangeMatch[4], 10);
            const bhp = parseInt(bhpMatch[1], 10);

            const matchesYear = details.registrationYear >= startYear && details.registrationYear <= endYear;
            const matchesBhp = parseInt(details.power, 10) === bhp;

            return matchesManufacturer && matchesModel && matchesYear && matchesBhp;
          }

          return matchesManufacturer && matchesModel;
        });


        if (filteredData.length > 0) {
          setAdditionalModelData(filteredData);
        } else {
          setAdditionalModelData([]); // Ensure state is set correctly
        }
      } catch (error) {
        console.error('Error fetching additional model data:', error);
        setAdditionalModelData([]); // Clear data on error
      }
    } else {
      setAdditionalModelData([]); // Clear data if incomplete details
    }
  };

  const toggleRow = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  /* KEKE */
  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === 'opti') {
        setCopiedOpti(true);
        setTimeout(() => setCopiedOpti(false), 2500);
      } else if (type === 'power') {
        setCopiedPower(true);
        setTimeout(() => setCopiedPower(false), 2500);
      }
    }, (err) => {
      console.error('Failed to copy text to clipboard', err);
    });
  };


  const handleLicensePlateChange = (e) => {
    setLicensePlate(e.target.value);
  };

  const handleSearch = async () => {
    await fetchVehicleData(licensePlate);
  };

  useEffect(() => {
    // Add event listener for Enter key press to trigger search
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent form submission
        handleSearch();
      }
    };

    const inputRef = licensePlateInputRef.current;
    if (inputRef) {
      inputRef.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (inputRef) {
        inputRef.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [licensePlate]);


  return (

    <div className="app-container">
             <div className="app-veh-column">
                 <div className='app-veh-parent'>

                         <div>
                             {/* VEHICLE SEARCH <br></br><br></br> */}


                <input
                  type="text"
                  value={licensePlate}
                  onChange={handleLicensePlateChange}
                  placeholder="Enter license plate"
                  ref={licensePlateInputRef}
                  className='app-veh-search'
                />
               <p></p>
                <button onClick={handleSearch} className='app-veh-button'>SØK</button>
              </div>


                 </div>
                 <br></br>
                 <div className='app-veh-center'>

              {/* {vehicleData ? ( */}
                {vehicleData && vehicleData.kjoretoydataListe && vehicleData.kjoretoydataListe[0] ? (
                <div className="svv-box">
                  <p className="detailtext"><b>MERKE:</b> {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke?.[0]?.merke || "Not available"}</p>
                  <p className="detailtext"><b>Brand:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse?.[0] || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Licenseplate:</b>  {vehicleData.kjoretoydataListe[0].kjoretoyId?.kjennemerke || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>VIN:</b>  {vehicleData.kjoretoydataListe[0].kjoretoyId?.understellsnummer || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Registration date:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Emission:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeBeskrivelse?.substring(0, 8) || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Particulatefilter:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.forbrukOgUtslipp?.[0]?.partikkelfilterFabrikkmontert ? 'Ja' : 'Nei'}</p>
                  <p className="detailtext"><b>Fueltype:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.drivstoffKodeMiljodata?.kodeBeskrivelse || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Enginesize:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.[0]?.slagvolum ? `${vehicleData.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.motorOgDrivverk.motor[0].slagvolum} cm3` : "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Gearbox:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeBeskrivelse || "Ikke tilgjengelig"}</p>
                  <p className="detailtext"><b>Power: </b>
                    {(() => {
                      const kW = vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.drivstoff[0]?.maksNettoEffekt;
                      if (kW) {
                        const hp = (kW * 1.36).toFixed(0);
                        return `${kW} kW / ${hp} HP`;
                      } else {
                        return "Not available";
                      }
                    })()}
                  </p>
                </div>
              ) : (
                <p>No vehicle data available. Please enter a license plate and search.</p>
              )}
              <div className='app-veh-info-parent'>
            {additionalModelData.length > 0 ? (
              additionalModelData.map((model, index) => (
                <div className='app-databasemodal-nowrap' key={index}>
                  <div className='app-veh-table'>
                  <div
            className={`app-model-entry ${index % 2 === 0 ? 'even' : 'odd'}`}
            onClick={() => toggleRow(index)}
            style={{ display: 'block', padding: '10px', borderBottom: '1px solid #ccc' }} // Block style for vertical layout
          >
                      <span className="app-model-description">{`${model.manufacturerName} ${model.rangeName} ${model.modelName}`}</span>
                    </div>
                    {activeIndex === index && (
                      <div className="app-text-details">
                        <p className='app-disc-opti'>
                          OPTI:<br />
                          {model.power_opti} Hp / {model.torque_opti} Nm<br />
                          <button className={copiedOpti ? 'copied' : ''} onClick={() => copyToClipboard(`${model.power_opti} Hp / ${model.torque_opti} Nm`, 'opti')}>
                            {copiedOpti ? 'Copied!' : 'Copy'}
                          </button>
                        </p>
                        <p className="app-disc-power">
                          POWER:<br />
                          {model.power} Hp / {model.torque} Nm<br />
                          <button className={copiedPower ? 'copied' : ''} onClick={() => copyToClipboard(`${model.power} Hp / ${model.torque} Nm`, 'power')}>
                            {copiedPower ? 'Copied!' : 'Copy'}
                          </button>
                        </p>
                        <p><span className='app-txt-bold'>OBD Location:</span><br />{model.obd_location}</p>
                        <p><span className='app-txt-bold'>ECU Location:</span><br />{model.ecu_location}</p>
                        <p><span className='app-txt-bold'>Notes:</span><br />{model.notes}</p>
                        <div className="app-button-group">
                          {/* Replace with actual implementation of UploadImageComponent */}
                          {/* <UploadImageComponent modelId={model.id} onUploadSuccess={handleImageUpload} /> */}

                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p>Searching for additional model data...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vehsearch;


// FYBGEREBDE

// import React, { useState, useEffect, useRef } from 'react';

// function Vehsearch({ fileId, onClose, userProfile }) {
//   const [vehicleData, setVehicleData] = useState(null);
//   const [licensePlate, setLicensePlate] = useState('');
//   const [isEditModalVisible, setIsEditModalVisible] = useState(false);

//   const fetchVehicleData = async (regNum) => {
//     try {
//       const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
//       if (!response.ok) {
//         throw new Error('Failed to fetch vehicle data');
//       }
//       const data = await response.json();
//       setVehicleData(data);
//     } catch (error) {
//       console.error('Error fetching vehicle data:', error);
//       setVehicleData(null); // Clear data on error
//     }
//   };

//   const handleLicensePlateChange = (e) => {
//     setLicensePlate(e.target.value);
//   };

//   const handleSearch = async () => {
//     await fetchVehicleData(licensePlate);
//   };

//   useEffect(() => {
//     // Fetch initial data if needed, e.g., for a default license plate or based on fileId
//     // fetchVehicleData(fileId); // Uncomment if you want to fetch data initially
//   }, []);

//   return (
//     <div className="filemodal-background">
//       <div className="filemodal-content" onClick={e => e.stopPropagation()}>
//         <div className="modal-header">
//           <button className="close-btn" onClick={onClose}>CLOSE</button>
//         </div>
//         <div className="content-wrapper">
//           <div className="column file-details-column">
//             <div className="info-box">
//               <div>
//                 <label><b>License Plate:</b></label>
//                 <input
//                   type="text"
//                   value={licensePlate}
//                   onChange={handleLicensePlateChange}
//                   placeholder="Enter license plate"
//                 />
//                 <button onClick={handleSearch}>Search</button>
//               </div>
//               {vehicleData ? (
//                 <div className="svv-box">
//                   <p className="detailtext"><b>Brand:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || "Not available"}</p>
//                   <p className="detailtext"><b>License Plate:</b> {vehicleData.kjoretoydataListe[0]?.kjoretoyId?.kjennemerke}</p>
//                   <p className="detailtext"><b>VIN:</b> {vehicleData.kjoretoydataListe[0]?.kjoretoyId?.understellsnummer}</p>
//                   <p className="detailtext"><b>Registration Date:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato}</p>
//                   <p className="detailtext"><b>Emission:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeBeskrivelse?.substring(0, 8) || "Not available"}</p>
//                   <p className="detailtext"><b>Particulate Filter:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe[0]?.forbrukOgUtslipp[0]?.partikkelfilterFabrikkmontert ? 'Yes' : 'No'}</p>
//                   <p className="detailtext"><b>Fuel Type:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe[0]?.drivstoffKodeMiljodata?.kodeBeskrivelse}</p>
//                   <p className="detailtext"><b>Engine Size:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.slagvolum} cm³</p>
//                   <p className="detailtext"><b>Gearbox:</b> {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeBeskrivelse}</p>
//                   <p className="detailtext"><b>Power:</b>
//                     {(() => {
//                       const kW = vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.drivstoff[0]?.maksNettoEffekt;
//                       if (kW) {
//                         const hp = (kW * 1.36).toFixed(0);
//                         return `${kW} kW / ${hp} HP`;
//                       } else {
//                         return "Not available";
//                       }
//                     })()}
//                   </p>
//                 </div>
//               ) : (
//                 <p>No vehicle data available. Please enter a license plate and search.</p>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Vehsearch;



// import React, { useState, useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
// import logo from '../assets/logo.png'; // Oppdater stien etter den faktiske plasseringen

// export default function Vehsearch({ openDatabaseModal, openUploadModal }) {
//     const [fileDetails, setFileDetails] = useState(null);
//     const [vehicleData, setVehicleData] = useState(null);
//     const [dealerCountry, setDealerCountry] = useState('');
//     const [registrationNumber, setRegistrationNumber] = useState('');
//     const [dealerCredit, setDealerCredit] = useState(null);

//     const fetchVehicleData = async (regNum) => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
//             if (!response.ok) {
//                 throw new Error('Failed to fetch vehicle data');
//             }
//             const data = await response.json();
//             setVehicleData(data);
//         } catch (error) {
//             console.error('Error fetching vehicle data:', error);
//         }
//     };

//     useEffect(() => {
//         if (fileDetails && fileDetails.regnum) {
//             fetchVehicleData(fileDetails.regnum);
//         }
//     }, [fileDetails]);

//     const fetchDealerDetails = async (dealerName) => {
//         try {
//             const response = await fetch(`${process.env.REACT_APP_SITE_URL}/dealer-details/${dealerName}`);
//             if (!response.ok) {
//                 throw new Error('Failed to fetch dealer details');
//             }
//             const data = await response.json();
//             return data;
//         } catch (error) {
//             console.error('Error fetching dealer details:', error);
//             return null;
//         }
//     };

//     useEffect(() => {
//         if (fileDetails && fileDetails.dealerName) {
//             fetchDealerDetails(fileDetails.dealerName).then(data => {
//                 if (data) {
//                     setDealerCountry(data.country);
//                     setDealerCredit(data.credit);
//                 } else {
//                     setDealerCountry(''); // Set to an empty string if fetch fails
//                 }
//             });
//         }
//     }, [fileDetails]);

//     const handleSearchClick = () => {
//         fetchVehicleData(registrationNumber);
//     };

//     return (
//         <div className="container">
//             <div className="all-column">
//                 <div className='all-parent'>
//                     <div className='all-mob2'>
//                         <div>
//                             VEHICLE SEARCH <br></br><br></br>
//                             <input
//                                 required
//                                 type="text"
//                                 value={registrationNumber}
//                                 onChange={(e) => setRegistrationNumber(e.target.value)}
//                                 placeholder="Registration Number"
//                             />
//                             <br></br><br></br>
//                             <button className="vehsearch-button" onClick={handleSearchClick}>SØK</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="profile-column">
//                 <br></br>
//                 {dealerCountry === 'NO' ? (
//                     vehicleData ? (
//                         <div className="svv-box">
//                             <p className="detailtext"><b>Brand:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || "Ikke tilgjengelig"}</p>
//                             <p className="detailtext"><b>Licenseplate:</b>  {vehicleData.kjoretoydataListe[0]?.kjoretoyId?.kjennemerke}</p>
//                             <p className="detailtext"><b>VIN:</b>  {vehicleData.kjoretoydataListe[0]?.kjoretoyId?.understellsnummer}</p>
//                             <p className="detailtext"><b>Registration date:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato}</p>
//                             <p className="detailtext"><b>Emission:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeBeskrivelse?.substring(0, 8) || "Ikke tilgjengelig"}</p>
//                             <p className="detailtext"><b>Particulatefilter:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe[0]?.forbrukOgUtslipp[0]?.partikkelfilterFabrikkmontert ? 'Ja' : 'Nei'}</p>
//                             <p className="detailtext"><b>Fueltype:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe[0]?.drivstoffKodeMiljodata?.kodeBeskrivelse}</p>
//                             <p className="detailtext"><b>Enginesize:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.slagvolum} cm3</p>
//                             <p className="detailtext"><b>Gearbox:</b>  {vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeBeskrivelse}</p>
//                             <p className="detailtext"><b>Power: </b>
//                                 {(() => {
//                                     const kW = vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.drivstoff[0]?.maksNettoEffekt;
//                                     if (kW) {
//                                         const hp = (kW * 1.36).toFixed(0); // Konverterer til HP og runder av til nærmeste heltall
//                                         return `${kW} kW / ${hp} HP`;
//                                     } else {
//                                         return "Ikke tilgjengelig";
//                                     }
//                                 })()}
//                             </p>
//                         </div>
//                     ) : (
//                         <p>Loading vehicle data...</p>
//                     )
//                 ) : dealerCountry === 'SE' ? (
//                     <div className="svv-box">
//                         <p>Kan inte henta dator for Sverige</p>
//                     </div>
//                 ) : (
//                     <div className="svv-box">
//                         <p>Data is not available for this country</p>
//                     </div>
//                 )}
//             </div>
//         </div>
//     );
// }
