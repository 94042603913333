import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './auth/AuthProvider';
import { UserProvider } from './context/UserContext';
import Login from './components/Login';
import TopBar from './components/TopBar';
import FilePortal from './components/FilePortal';
import Database from './components/Database';
import Upload from './components/Upload';
import Support from './components/Support';
import Opticenter from './components/Opticenter';
import Profilesite from './components/Profilesite';
import Adminsite from './components/Adminsite';
import FileDetails from './components/FileDetails';

// ANDERS
import Info from './components/Info';
import Oohsite from './components/Oohsite';
import Vehsearch from './components/Vehsearch';


import NotFound from './components/NotFound';
import './App.css';

function ProtectedRoute({ children }) {
    const { authToken } = useAuth();
    return authToken ? children : <Navigate to="/login" />;
}

function App() {
    const [showDatabaseModal, setShowDatabaseModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showAdminModal, setShowAdminModal] = useState(false);

    const openDatabaseModal = () => setShowDatabaseModal(true);
    const closeDatabaseModal = () => setShowDatabaseModal(false);
    const openUploadModal = () => setShowUploadModal(true);
    const closeUploadModal = () => setShowUploadModal(false);
    const openProfileModal = () => setShowProfileModal(true);
    const closeProfileModal = () => setShowProfileModal(false);
    const openAdminModal = () => setShowAdminModal(true);
    const closeAdminModal = () => setShowAdminModal(false);

    return (
        <AuthProvider>
            <UserProvider>
                <Router>
                {/* <TopBar openDatabaseModal={openDatabaseModal} openUploadModal={openUploadModal} openProfileModal={openProfileModal} openAdminModal={openAdminModal} /> */}
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={<ProtectedRoute><FilePortal /></ProtectedRoute>} />
                        <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
                        <Route path="/opticenter" element={<ProtectedRoute><Opticenter /></ProtectedRoute>} />
                        <Route path="/profilesite" element={<ProtectedRoute><Profilesite /></ProtectedRoute>} />
                        <Route path="/adminsite" element={<ProtectedRoute><Adminsite /></ProtectedRoute>} />
                        <Route path="/support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
                        <Route path="/database" element={<ProtectedRoute><Database /></ProtectedRoute>} />
                        <Route path="/oohsite" element={<ProtectedRoute><Oohsite /></ProtectedRoute>} />
                        <Route path="/info" element={<ProtectedRoute><Info /></ProtectedRoute>} />
                        <Route path="/vehsearch" element={<ProtectedRoute><Vehsearch /></ProtectedRoute>} />
                        <Route path="/file/:id" element={<ProtectedRoute><FileDetails /></ProtectedRoute>} />
                        {/* /* FJERNES */}
                        <Route path="/upload" element={<ProtectedRoute><Upload /></ProtectedRoute>} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                    {showDatabaseModal && <Database onClose={closeDatabaseModal} />}
                    {showUploadModal && <Upload onClose={closeUploadModal} />}
                    {showProfileModal && <Profilesite onClose={closeProfileModal} />}
                    {showAdminModal && <Adminsite onClose={closeAdminModal} />}
                </Router>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;
