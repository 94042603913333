import React, { useState, useEffect, useRef } from 'react';
import filecomupload from '../assets/filecomupload.png';
import filecomredo from '../assets/filecomredo.png';
import filecomcredit from '../assets/filecomcredit.png';
import filecomphoto from '../assets/filecomphoto.png';

function FileDetails({ fileId, onClose, isAdmin, isTuner, userProfile }) {
  const [fileDetails, setFileDetails] = useState(null);
  const [dialogs, setDialogs] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isInternal, setIsInternal] = useState(false);
  const [showForm, setShowForm] = useState(null);
  const fileInputRef = useRef(null);

  const photoInputRef = useRef(null); // Added ref for photo input

  const [selectedFiles, setSelectedFiles] = useState([]);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dealerNames, setDealerNames] = useState({}); // New state for dealer names
  const [isUploadModPopupVisible, setIsUploadModPopupVisible] = useState(false);
  const [modFile, setModFile] = useState(null);
  const [masterFile, setMasterFile] = useState(null);
  const [gearboxFile, setGearboxFile] = useState(null);
  const [gearboxMasterFile, setGearboxMasterFile] = useState(null);
  const [textInput1, setTextInput1] = useState('');
  const [textInput2, setTextInput2] = useState('');
  const [commentId, setCommentId] = useState('');
  const [fileOptions, setFileOptions] = useState({}); // New state for file options
  const [selectedFilecomId, setSelectedFilecomId] = useState(null); // New state for selected filecom id
  const [vehicleData, setVehicleData] = useState(null);
  const [dealerCountry, setDealerCountry] = useState('');
  const [dealerCredit, setDealerCredit] = useState('');
  const [isAdvancedVisible, setIsAdvancedVisible] = useState(false); // State to manage visibility of the advanced options
  const [selectedOptions, setSelectedOptions] = useState({}); // State to manage selected options

  const calculateEstimatedCredits = () => {
    let credits = 0;
    if (selectedOptions.egr) credits += 3;
    if (selectedOptions.dpf) credits += 3;
    if (selectedOptions.adblue) credits += 5;
    // Add more options and their credit values as needed
    return credits;
  };

  const handleAdvancedToggle = () => {
    setIsAdvancedVisible(!isAdvancedVisible);
  };

  const handleOptionChange = (optionName, value) => {
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [optionName]: value,
    }));
  };

  const fetchVehicleData = async (regNum) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
      if (!response.ok) {
        throw new Error('Failed to fetch vehicle data');
      }
      const data = await response.json();
      setVehicleData(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    }
  };

  useEffect(() => {
    if (fileDetails && fileDetails.regnum) {
      fetchVehicleData(fileDetails.regnum);
    }
  }, [fileDetails]);

  const fetchDealerDetails = async (dealerName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/dealer-details/${dealerName}`);
      if (!response.ok) {
        throw new Error('Failed to fetch dealer details');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching dealer details:', error);
      return null;
    }
  };
  useEffect(() => {
    if (fileDetails && fileDetails.dealerName) {
      fetchDealerDetails(fileDetails.dealerName).then(data => {
        if (data) {
          setDealerCountry(data.country);
          setDealerCredit(data.credit);
        }
      });
    }
  }, [fileDetails]);


  const handleUploadModSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    if (modFile) formData.append('modFile', modFile);
    if (masterFile) formData.append('masterFile', masterFile);
    if (gearboxFile) formData.append('gearboxFile', gearboxFile);
    if (gearboxMasterFile) formData.append('gearboxMasterFile', gearboxMasterFile);
    formData.append('textInput1', textInput1);
    formData.append('textInput2', textInput2);
    formData.append('fileId', fileId); // Sørg for at fileId blir sendt
    formData.append('filecomid', selectedFilecomId); // Sørg for at selectedFilecomId blir sendt
    formData.append('user', userProfile.name); // Legg til user

    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadModFiles`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        console.error('Failed to upload files');
        return;
      }

      setIsUploadModPopupVisible(false);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const renderAdvancedOptions = () => {
    return (
      <div className="advanced-options">
        <h3>Advanced Options</h3>
        <label>
          <input
            type="checkbox"
            checked={selectedOptions.egr || false}
            onChange={(e) => handleOptionChange('egr', e.target.checked)}
          />
          EGR (3 credits)
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedOptions.dpf || false}
            onChange={(e) => handleOptionChange('dpf', e.target.checked)}
          />
          DPF (3 credits)
        </label>
        <label>
          <input
            type="checkbox"
            checked={selectedOptions.adblue || false}
            onChange={(e) => handleOptionChange('adblue', e.target.checked)}
          />
          AdBlue (5 credits)
        </label>
        {/* Add more options here */}
        <p>Estimated Credits: {calculateEstimatedCredits()}</p>
      </div>
    );
  };

  const transmissionOptions = {
    2: 'Auto',
    3: 'Manual',
    4: 'DSG',
  };

  const fuelOptions = {
    2: 'Diesel',
    3: 'Petrol',
    5: 'Hybrid',
  };

  const tuningToolOptions = {
    1: 'Autotuner',
    2: 'MMFlex',
    3: 'NewGenius',
    9: 'Unlisted - Other tool',
    10: 'Trasdata',
    11: 'Kess V3',
    12: 'K-Tag',
  };

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [dialogs]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newMessage]);

  const handleTextareaInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const getMessageType = (dialog) => {
    let classes = [];
    if (dialog.internal === 1) classes.push('internal-message');
    if (dialog.user === fileDetails.dealerName) classes.push('app-dealer-message');
    else classes.push('app-admin-message');
    if (dialog.rredo === 1) classes.push('app-redo-message');
    if (dialog.rcredit === 1) classes.push('rcredit-message');
    if (dialog.url) classes.push('url-message');
    if (dialog.uploadurl) classes.push('uploadurl-message');
    if (dialog.uploaded === 1) classes.push('uploaded-message');
    return classes.join(' ');
  };

  // const handleFileChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedFiles(files);
  //   fileInputRef.current.files = event.target.files;
  //   setDropdownVisible(false);
  // };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const resizedFiles = [];

    // Process each file
    files.forEach((file) => {
      if (file.type.startsWith('image/')) {
        // Handle image files by resizing
        const reader = new FileReader();
        reader.onload = function (loadEvent) {
          const img = new Image();
          img.onload = function () {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 2000;
            const maxHeight = 1000;
            let width = img.width;
            let height = img.height;

            // Maintain aspect ratio and resize
            if (width > height) {
              if (width > maxWidth) {
                height = Math.floor((height * maxWidth) / width);
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width = Math.floor((width * maxHeight) / height);
                height = maxHeight;
              }
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            // Convert canvas content to Blob and then File
            canvas.toBlob((blob) => {
              resizedFiles.push(new File([blob], file.name, { type: file.type }));
              if (resizedFiles.length === files.length) {
                setSelectedFiles(resizedFiles); // All files processed
                fileInputRef.current.files = event.target.files; // Update ref to point to original files
              }
            }, file.type);
          };
          img.src = loadEvent.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        // Non-image files are directly added
        resizedFiles.push(file);
        if (resizedFiles.length === files.length) {
          setSelectedFiles(resizedFiles); // All files processed
          fileInputRef.current.files = event.target.files; // Update ref to point to original files
        }
      }
    });

    setDropdownVisible(false);
  };

  /* ANDY ADD */

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handlePhotoInputClick = () => {
    if (photoInputRef.current) {
      photoInputRef.current.click();
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    const dataTransfer = new DataTransfer();
    updatedFiles.forEach(file => dataTransfer.items.add(file));
    fileInputRef.current.files = dataTransfer.files;
  };

  function isImage(url) {
    return /\.(jpg|jpeg|png|gif)$/.test(url);
  }


  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleInternalChange = (event) => {
    setIsInternal(event.target.checked);
  };

  const handleRequestComment = () => {
    setShowForm('comment');
    setNewMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const files = fileInputRef.current ? fileInputRef.current.files : [];
    let imageUrls = [];

    if (files && files.length > 0) {
      const formData = new FormData();
      Array.from(files).forEach(file => {
        formData.append('filecom', file);
      });

      try {
        const uploadResponse = await fetch(process.env.REACT_APP_SITE_URL + '/uploadFilecom', {
          method: 'POST',
          body: formData,
        });
        const uploadData = await uploadResponse.json();

        if (!uploadResponse.ok) {
          console.error('Error uploading files:', uploadData.message);
          return;
        }
        imageUrls = uploadData.urls;
      } catch (error) {
        console.error('Error during file upload:', error);
        return;
      }
    }

    const payload = {
      fileId: fileId,
      user: userProfile.name,
      message: newMessage,
      internal: isInternal ? 1 : 0,
      rredo: showForm === 'redo' ? 1 : 0,
      rcredit: showForm === 'creditback' ? 1 : 0,
      urls: imageUrls.join(','),
      dealercheck: isAdmin ? 1 : 0,
      tunercheck: isAdmin ? 0 : 1,
    };

    try {
      const messageResponse = await fetch(process.env.REACT_APP_SITE_URL + '/postMessage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const messageData = await messageResponse.json();

      if (!messageResponse.ok) {
        console.error('Error posting message:', messageData.message);
        return;
      }

      setDialogs(prevDialogs => [...prevDialogs, payload]);
      setNewMessage('');
      setIsInternal(false);
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setShowForm(null);
    } catch (error) {
      console.error('Error posting message:', error);
    }
  };

  const renderLinks = (urlsString) => {
    if (!urlsString) return null;

    const urls = urlsString.split(',');
    const imageElements = urls.filter(isImage).map((url, index) => (
      <img
        key={index}
        src={url}
        alt={`Uploaded File ${index + 1}`}
        className="thumbnail"
        onClick={() => window.open(url, '_blank')}
      />
    ));

    const downloadLinkElements = urls.filter(url => !isImage(url)).map((url, index) => (
      <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="download-link">
        {url.split('/').pop()}
      </a>
    ));

    return (
      <>
        <div className="thumbnails-container">{imageElements}</div>
        <div className="download-links-container">{downloadLinkElements}</div>
      </>
    );
  };

  useEffect(() => {

  }, [newMessage, selectedFiles]);

  const handleWipChange = async () => {
    const newWipStatus = !fileDetails.wip;
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + `/updateWipStatus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userProfile.token}` },
        body: JSON.stringify({ fileId: fileDetails.id, wip: newWipStatus })
      });
      if (response.ok) {

        setFileDetails(prevDetails => ({ ...prevDetails, wip: newWipStatus }));
      } else {
        throw new Error('Failed to update WIP status');
      }
    } catch (error) {
      console.error('Error updating WIP status:', error);
    }
  };






  const fetchFileOptions = async (filecomid) => {
    try {

      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/file-options/${filecomid}`);
      if (!response.ok) {
        throw new Error('Failed to fetch file options');
      }
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching file options:', error);
      return null;
    }
  };










  const constructAvatarUrl = (relativePath) => {
    let baseUrl = process.env.REACT_APP_SITE_URL;
    if (baseUrl.includes('/api')) {
        baseUrl = baseUrl.split('/api')[0];
    }
    return `${baseUrl}${relativePath}`;
};


const MessageAvatar = ({ dialog }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
      if (dialog.avatar) {
          const constructedAvatarUrl = constructAvatarUrl(dialog.avatar);
          setAvatarUrl(constructedAvatarUrl);
      }
  }, [dialog.avatar]);

  return (
      <div className="message-avatar">
          {avatarUrl ? (
              <img src={avatarUrl} alt="Avatar" className="avatar-icon" />
          ) : (
              <>
                  {getMessageType(dialog).includes('dealer-message') && <span className="avatar-icon">💼</span>}
                  {getMessageType(dialog).includes('admin-message') && <span className="avatar-icon">👤</span>}
              </>
          )}
      </div>
  );
};

const handleDownload = (url) => {

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'file'); // Use actual filename if available
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleUpdateStatus = async (id, url) => {
  try {
    // Oppdater status
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 1 }),
    });

    if (!response.ok) {
      console.error('Failed to update status');
      return;
    }


    const payload = {
      fileId: fileDetails.id,
      user: 'Optifiles',
      message: 'File has been downloaded and status set to in progress.',
      internal: isInternal ? 1 : 0,
      rredo: 0,
      rcredit: 0,
      urls: '',
      dealercheck: isAdmin ? 0 : 0,
      tunercheck: isAdmin ? 0 : 1,
    };

    const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!messageResponse.ok) {
      const messageData = await messageResponse.json();
      console.error('Error posting message:', messageData.message);
      return;
    }


    setDialogs(prevDialogs => [...prevDialogs, payload]);

    // Last ned filen
    handleDownload(url);

  } catch (error) {
    console.error('Error updating status or posting message:', error);
  }
};


const getTuningTypeClass = (tuningTypeValue) => {
  switch (tuningTypeValue.toLowerCase()) {
    case 'eco':
      return 'tuning-type-eco';
    case 'opti':
      return 'tuning-type-opti';
    case 'power':
      return 'tuning-type-power';
    case 'as stock':
      return 'tuning-type-as-stock';
    case 'extreme':
      return 'tuning-type-extreme';
    default:
      return '';
  }
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const detailsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/file/${fileId}`);
        const detailsData = await detailsResponse.json();
        setFileDetails(detailsData);

        const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
        const dialogsData = await dialogsResponse.json();

        const updatedDialogs = await Promise.all(dialogsData.map(async (dialog) => {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
          const data = await response.json();

          let companyName = data.companyName;
          let companyStyle = { color: 'green' };

          if (data.admin === 1 || data.tuner === 1) {
            companyName = 'Optifiles';
            companyStyle = { color: 'red' };
          }

          if (data.support === 1) {
            companyName += '';
            companyStyle = { color: 'green' };
          }

          const fileOptions = await fetchFileOptions(dialog.id);

          return { ...dialog, companyName, companyStyle, isSupport: data.support === 1, fileOptions };
        }));

        setDialogs(updatedDialogs);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [fileId]);






  if (!fileDetails) return <div>No file details available.</div>;

  return (
    <div className="app-filemodal-background">

      <div className="app-filemodal-content" onClick={e => e.stopPropagation()}>
        <div className="app-modal-header">
          <button className="close-btn" onClick={onClose}>CLOSE</button>
        </div>
        <div className="app-content-wrapper">
          {/* DENNEHER */}
              <div className="app-column app-file-details-column">
            <div className="info-box">
              <p className="detailtextmedium"><b> {fileDetails.vehicleDescription}</b></p>
              <p className="detailtext"><b>ID:</b> {fileDetails.id}</p>
                <br />
              <div className="wip-toggle">
                {isAdmin && (
                  <button
                    type="button"
                    onClick={handleWipChange}
                    className={`wip-button ${fileDetails.wip ? 'active' : ''}`}
                  >
                    Work In Progress
                  </button>
                )}
              </div>
              <br />





              {/* {dealerCountry === 'NO' ? (
  vehicleData ? ( */}

{dealerCountry === 'NO' ? (
  vehicleData && vehicleData.kjoretoydataListe && vehicleData.kjoretoydataListe[0] ? (
    <div className="svv-box">
      <p className="detailtext"><b>Brand:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse?.[0] || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Licenseplate:</b>  {vehicleData.kjoretoydataListe[0].kjoretoyId?.kjennemerke || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>VIN:</b>  {vehicleData.kjoretoydataListe[0].kjoretoyId?.understellsnummer || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Registration date:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Emission:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeBeskrivelse?.substring(0, 8) || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Particulatefilter:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.forbrukOgUtslipp?.[0]?.partikkelfilterFabrikkmontert ? 'Ja' : 'Nei'}</p>
      <p className="detailtext"><b>Fueltype:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.drivstoffKodeMiljodata?.kodeBeskrivelse || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Enginesize:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.[0]?.slagvolum ? `${vehicleData.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.motorOgDrivverk.motor[0].slagvolum} cm3` : "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Gearbox:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeBeskrivelse || "Ikke tilgjengelig"}</p>
      <p className="detailtext"><b>Power: </b>
                  {(() => {
                    const kW = vehicleData.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor[0]?.drivstoff[0]?.maksNettoEffekt;
                    if (kW) {
                      const hp = (kW * 1.36).toFixed(0); // Konverterer til HP og runder av til nærmeste heltall
                      return `${kW} kW / ${hp} HP`;
                    } else {
                      return "Ikke tilgjengelig";
                    }
                  })()}
                </p>
              </div>
            ) : (
              <p>Loading vehicle data...</p>
            )
          ) : dealerCountry === 'SE' ? (
            <div className="svv-box">
            <p>Kan inte henta dator for Sverige</p>
            </div>
          ) : (
            <div className="svv-box">
            <p>Data is not available for this country</p>
            </div>
          )}


<div className="info-box">
              <p className="detailtextmedium"><b>{fileDetails.dealerName}<br /></b> {dealerCredit} credits left<br /></p>
              <p className="detailtext"><b>Tuningtool:</b> {tuningToolOptions[fileDetails.tooltype]}</p>
              <p className="detailtext"><b>Uploaded:</b> {new Date(fileDetails.created).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.created).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
              <p className="detailtext"><b>Modified:</b> {new Date(fileDetails.updated).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.updated).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
              <p className="detailtext"><b>Charge:</b> -16</p>
            </div>



            </div>



          {/* CHAT PÅ BUNN */}
{/* fra den under i tillegg app-message-column */}
          <div className="app-column app-message-column">
            <div className="app-messages" >

            {/* <div contentEditable="true" data-text="There are no messages in dialog yet"></div> */}

            {Array.isArray(dialogs) && dialogs.map((dialog, index) => (
  (dialog.internal === 0 || isAdmin || isTuner) && (
    <div key={index} className={` ${getMessageType(dialog)}`} >
      <div className="app-dialog-message" >
        <MessageAvatar dialog={dialog} />

        <div className="app-message-content">
          <div className="app-message-header">
            <span className="dialog-user"><b>{dialog.user}</b></span>
            <span className="dialog-date">{new Date(dialog.date).toLocaleString('no-NO', { timeZone: 'UTC' })}</span>
          </div>
          <div className="company-name" style={dialog.companyStyle}>
            {dialog.companyName}
            {dialog.isSupport && <span style={{ color: 'blue' }}> (Support)</span>}
          </div>
          <div className="app-message-body" >
          <p className="dialog-message-content" >{dialog.message}</p>


          {dialog.message.startsWith("File upload:") && (
    <>
      <div className="dialogdownload-buttons">

        {(isAdmin || isTuner) && (
          <>

          <div>ECU FILES:</div>



            <button onClick={() => handleUpdateStatus(fileDetails.id, dialog.oriurl)} className="oridownload-button">Original</button>
            <button onClick={() => handleDownload(dialog.oriurl)} className="orihiddownload-button">Ori (Hidden)</button>
            {fileDetails.tooltype === 1 && (
              <button onClick={() => handleDownload(dialog.oriurl)} className="oriencdownload-button">Ori (Encrypted)</button>
            )}
          </>
        )}
      </div>
      <div className="dialogdownload-buttons">

        {(isAdmin || isTuner) && (

          <>
          <div>ACM / TCU FILES:</div>
            <button onClick={() => handleDownload(dialog.gearurl)} className="oridownload-button">Original</button>
            {fileDetails.tooltype === 1 && (
              <button onClick={() => handleDownload(dialog.gearurl)} className="oriencdownload-button">Ori (Encrypted)</button>
            )}
          </>
        )}
      </div>
      {(isAdmin || isTuner) && (
        <div className="dialogdownload-buttons">
          <button onClick={() => { setIsUploadModPopupVisible(true); setSelectedFilecomId(dialog.id); }} className="updownload-button">Upload Mod</button>


        </div>
      )}
    </>
  )}


            {isUploadModPopupVisible && (
              <div className="upload-mod-popup-background">
                <div className="upload-mod-popup-content" onClick={e => e.stopPropagation()}>
                  <div className="modal-header">
                    <button className="close-btn" onClick={onClose}>CLOSE</button>
                  </div>
                  <form onSubmit={handleUploadModSubmit}>
                    <div className="file-input-row">
                      <div className="file-input-column">
                        <label className="file-input-label">Modfile:</label>
                        <input className="unique-file-input" type="file" name="modFile" onChange={e => setModFile(e.target.files[0])} />
                      </div>
                      <div className="file-input-column">
                        <label className="file-input-label">Master:</label>
                        <input className="unique-file-input" type="file" name="masterFile" onChange={e => setMasterFile(e.target.files[0])} />
                      </div>
                      <div className="file-input-column">
                        <label className="file-input-label">Gearbox:</label>
                        <input className="unique-file-input" type="file" name="gearboxFile" onChange={e => setGearboxFile(e.target.files[0])} />
                      </div>
                      <div className="file-input-column">
                        <label className="file-input-label">Gearbox Master:</label>
                        <input className="unique-file-input" type="file" name="gearboxMasterFile" onChange={e => setGearboxMasterFile(e.target.files[0])} />
                      </div>
                    </div>
                    <div className="text-input-row">
                      <label className="text-input-label-uploadcomment">Dealerkommentar:</label>
                      <textarea className="unique-text-input" value={textInput1} onChange={e => setTextInput1(e.target.value)} />
                    </div>
                    <div className="text-input-row">
                      <label className="text-input-label-uploadcomment">Internkommentar:</label>
                      <textarea className="unique-text-input" value={textInput2} onChange={e => setTextInput2(e.target.value)} />
                    </div>
                    <input type="hidden" value={dialog.id} />
                    <button className="admin-button" type="submit">Upload</button>
                  </form>
                  
                </div>
              </div>
            )}
            {dialog.fileOptions && (
              <div className="file-options">
                {dialog.fileOptions.tuning_type_value && (
                  <p className={`file-option tuning-type ${getTuningTypeClass(dialog.fileOptions.tuning_type_value)}`}>
                    {dialog.fileOptions.tuning_type_value}
                  </p>
                )}
                {dialog.fileOptions.egr !== 0 && <p className="file-option egr">EGR</p>}
                {dialog.fileOptions.dpf !== 0 && <p className="file-option dpf">DPF</p>}
                {dialog.fileOptions.adblue !== 0 && <p className="file-option adblue">AdBlue</p>}
                {dialog.fileOptions.nsl !== 0 && <p className="file-option nsl">NSL</p>}
                {dialog.fileOptions.o2 !== 0 && <p className="file-option o2">O2</p>}
                {dialog.fileOptions.epa !== 0 && <p className="file-option epa">EPA</p>}
                {dialog.fileOptions.hybridturbo !== 0 && <p className="file-option hybrid-turbo">Hybrid Turbo</p>}
                {dialog.fileOptions.gearbox !== 0 && <p className="file-option gearbox">Gearbox</p>}
                {dialog.fileOptions.coldstart !== 0 && <p className="file-option cold-start">Cold Start</p>}
                {dialog.fileOptions.startstop !== 0 && <p className="file-option start-stop">Start/Stop</p>}
                {dialog.fileOptions.popsbangs !== 0 && <p className="file-option pops-bangs">Pops & Bangs</p>}
                {dialog.fileOptions.burbles !== 0 && <p className="file-option burbles">Burbles</p>}
                {dialog.fileOptions.swirlflaps !== 0 && <p className="file-option swirl-flaps">Swirl Flaps</p>}
                {dialog.fileOptions.tva !== 0 && <p className="file-option tva">TVA</p>}
                {dialog.fileOptions.priority_value && <p className="file-option priority">{dialog.fileOptions.priority_value}</p>}
              </div>
            )}
            {dialog.uploadurl && renderLinks(dialog.uploadurl)}
            {dialog.url && renderLinks(dialog.url)}
            {dialog.message.startsWith("File upload:") && (
              <>
                {dialog.modmessage && (
                  <div className="dialogdownload-buttons">
                    <button onClick={() => handleDownload(dialog.modurl)} className="finishdownload-button">ECU MOD</button>
                    <button onClick={() => handleDownload(dialog.modgearurl)} className="finishdownload-button">ACM / TCU MOD</button>
                    {(isAdmin || isTuner) && (
                      <>
                        <button onClick={() => handleDownload(dialog.modmasterurl)} className="finishdownloadmaster-button">ECU MASTER</button>
                        <button onClick={() => handleDownload(dialog.modgearmasterurl)} className="finishdownloadmaster-button">ACM / TCU MASTER</button>
                      </>
                    )}
                  </div>
                )}
                {dialog.modmessage}
              </>
            )}




            <div className="row-icons">
              {dialog.rredo === 1 && <span className="material-symbols-outlined">change_circle</span>}
              {dialog.rcredit === 1 && <span className="material-symbols-outlined">account_balance_wallet</span>}
              {dialog.uploaded === 1 && <div className="row-uploadedfile">UPLOADED FILE</div>}
            </div>
            {dialog.rredo === 1 && isAdmin && (
              <div className="row-answer">
                <div className="answer-button">Last opp fil, HER SVARER MAN</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
))}



              <div ref={messagesEndRef} />
            </div>

            <div className="selected-files">
              {selectedFiles.map((file, index) => (
                <div key={index} className="selected-file">
                  {file.name}
                  <button type="button" className="remove-file-button" onClick={() => handleRemoveFile(index)}>
                    &times;
                  </button>
                </div>
              ))}
            </div>
            {/* app-message-form */}
            {/* <div className='andy-app-message-form'> */}
            <div className='app-outer-container'>
              <form onSubmit={handleSubmit} className="app-chat-container2">
                <table className="app-chat-table">
                <tr>
          <td className='app-chat-table-cell'>
        <div className="dropdown-container submitbox">
          <button type="button" onClick={toggleDropdown} className="upload-button app-button">+</button>

          {dropdownVisible && (
            <div className="dropdown-menu">
              <button type="button" onClick={() => fileInputRef.current.click()}>
                <img src={filecomupload} alt="Alert" className="filecompng" />Upload a File
              </button>
              <button type="button" onClick={handlePhotoInputClick}>
                              <img src={filecomphoto} alt="Camera" className="filecompng" />Take a Photo
                            </button>
              <button type="button" onClick={handleRequestComment}>
                <img src={filecomredo} alt="Alert" className="filecompng" />Request redo
              </button>
              <button type="button" onClick={handleRequestComment}>
                <img src={filecomcredit} alt="Alert" className="filecompng" />Request creditback
              </button>
            </div>
          )}
        </div>
        </td>
        <input type="file" ref={fileInputRef} onChange={handleFileChange} multiple style={{ display: 'none' }} />

                    <input
                      type="file"
                      ref={photoInputRef}
                      onChange={handleFileChange}
                      accept="image/*"
                      capture="environment"  // Specifies the back camera
                      style={{ display: 'none' }}
                    />


        <td className='app-chat-table-cell'>
        <textarea
        className='app-text-area'
        rows='1'
          ref={textareaRef}
          value={newMessage}
          onChange={e => {
            handleNewMessageChange(e);
            handleTextareaInput();
          }}
          placeholder="Message..."
          style={{ resize: 'none', overflow: 'hidden' }}
        /></td>
        {isAdmin && (

          <td className='app-chat-table-cell'>

                <input
                  type="checkbox"
                  className='app-checkbox-int'
                  checked={isInternal}
                  onChange={handleInternalChange}
                />
                {/* <label>Internal</label> */}
                </td>


            )}
        <td className='app-chat-table-cell'>
        <button type="submit" className="app-send-button sendbox">➤</button></td>

            </tr>
            </table>
          </form>
      </div>
      <div className='app-chat-padding'>
          </div>
          </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default FileDetails;