import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function Profilesite({ onClose, username, usernameid, did, country }) {
    const [activeSection, setActiveSection] = useState('Overview');
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [filter, setFilter] = useState('All');
    const [showTaskModal, setShowTaskModal] = useState(false);
    const [newTask, setNewTask] = useState({ vehicle: '', info: '', customer: '', datetime: '' });
    const [selectedTask, setSelectedTask] = useState(null);
    const [uploadsData, setUploadsData] = useState([]);
    const [userStats, setUserStats] = useState({
        rank: 0,
        filesNeededToRankup: 0,
        filesAwayFromRankdown: 0,
        totalFiles: 0,
        todayFiles: 0,
        weeklyFiles: 0,
        monthlyFiles: 0,
        yearlyFiles: 0
    });
    const [companyLevel, setCompanyLevel] = useState({
        level: '',
        baseCredits: ''
    });
    const [profileData, setProfileData] = useState({
        name: '',
        legal: '',
        phone: '',
        vat: '',
        address: '',
        employees: []
    });
    const [avatar, setAvatar] = useState('');
    const [hotManufacturer, setHotManufacturer] = useState('');
    const [hotOption, setHotOption] = useState('');
    const [hotTool, setHotTool] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editProfileData, setEditProfileData] = useState({
        name: '',
        phone: '',
        email: '',
        avatar: ''
    });

    const [profilePictureFile, setProfilePictureFile] = useState(null);
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        if (activeSection === 'Overview') {
            fetchUploadsData();
            fetchUserStats();
            fetchProfileData();
            fetchAvatar();
            fetchHotItems();
            fetchCompanyLevel();
        } else if (activeSection === 'Activity') {
            fetchLogs();
        }
    }, [activeSection, did]);

    useEffect(() => {
        if (activeSection === 'Tasks') {
            fetchTasks();
        }
    }, [activeSection]);

    useEffect(() => {
        filterTasks(filter);
    }, [tasks, filter]);

    useEffect(() => {
        fetchUploadsData(did);
    }, [did]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose]);

    const fetchUserStats = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/stats/${did}`);
            const data = await response.json();
            setUserStats(data);
        } catch (error) {
            console.error('Error fetching user stats:', error);
        }
    };

    const fetchCompanyLevel = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/calculate-credits/${did}`);
            const data = await response.json();
            setCompanyLevel(data);
        } catch (error) {
            console.error('Error fetching user stats:', error);
        }
    };

    const fetchProfileData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/members/profile/${did}`);
            const data = await response.json();
            setProfileData(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const fetchEditProfileData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/editable-profile/${usernameid}`);
            const data = await response.json();
            setEditProfileData(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };

    const openEditModal = () => {
        fetchEditProfileData();
        setShowEditModal(true);
    };

    const fetchAvatar = async () => {
        try {
            const fetchUrl = `${process.env.REACT_APP_SITE_URL}/members/avatar/${usernameid}`;

            const response = await fetch(fetchUrl);
            const data = await response.json();

            // Log the data to see what is fetched

            // Ensure baseUrl does not contain duplicate '/api'
            let baseUrl = process.env.REACT_APP_SITE_URL;
            if (baseUrl.includes('/api')) {
                baseUrl = baseUrl.split('/api')[0];
            }

            const avatarUrl = `${baseUrl}${data.avatar}`;
            setAvatar(avatarUrl);
        } catch (error) {
            console.error('Error fetching avatar:', error);
        }
    };





    const fetchHotItems = async () => {
        try {
            const hotManufacturerResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/hot-manufacturer/${did}`);
            const hotManufacturerData = await hotManufacturerResponse.json();
            setHotManufacturer(hotManufacturerData.value);

            const hotOptionResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/hot-option/${did}`);
            const hotOptionData = await hotOptionResponse.json();
            setHotOption(hotOptionData.value);

            const hotToolResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/hot-tool/${did}`);
            const hotToolData = await hotToolResponse.json();
            setHotTool(hotToolData.value);
        } catch (error) {
            console.error('Error fetching hot items:', error);
        }
    };

    const fetchUploadsData = async (did) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/charged-data-per-member?mid=${did}`);
            const data = await response.json();
            setUploadsData(data);
        } catch (error) {
            console.error('Error fetching uploads data:', error);
        }
    };

    const fetchTasks = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks?did=${did}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            setTasks(data);
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const fetchLogs = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/logsite/logs/dealer/${did}`);
            const data = await response.json();
            setLogs(data);
        } catch (error) {
            console.error('Error fetching logs:', error);
        }
    };

    const filterTasks = (filter) => {
        const now = new Date();
        let filtered = tasks;

        switch (filter) {
            case 'Today':
                filtered = tasks.filter(task => new Date(task.datetime).toDateString() === now.toDateString());
                break;
            case 'Week':
                const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
                const endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(startOfWeek.getDate() + 6);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfWeek && new Date(task.datetime) <= endOfWeek);
                break;
            case 'Month':
                const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
                const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfMonth && new Date(task.datetime) <= endOfMonth);
                break;
            case 'Year':
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                const endOfYear = new Date(now.getFullYear(), 11, 31);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfYear && new Date(task.datetime) <= endOfYear);
                break;
            case 'All':
            default:
                filtered = tasks;
                break;
        }

        setFilteredTasks(filtered);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTask(prevState => ({ ...prevState, [name]: value }));
    };

    const handleCreateTask = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ...newTask, did }),
            });
            if (response.ok) {
                fetchTasks();
                setShowTaskModal(false);
                setNewTask({ vehicle: '', info: '', customer: '', datetime: '' });
            } else {
                console.error('Error creating task');
            }
        } catch (error) {
            console.error('Error creating task:', error);
        }
    };

    const handleTaskClick = (task) => {
        setSelectedTask({
            ...task,
            datetime: getInputDateTimeString(task.datetime)
        });
    };

    const handleModalInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedTask(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSaveTask = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${selectedTask.id}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(selectedTask),
            });
            if (response.ok) {
                fetchTasks();
                setSelectedTask(null);
            } else {
                console.error('Error updating task');
            }
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };

    const handleMarkAsDone = async (taskId) => {
        const confirm = window.confirm("Are you sure you want to mark this task as complete? This action cannot be undone.");
        if (!confirm) {
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/${taskId}/done`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ done: 1 }),
            });
            if (response.ok) {
                fetchTasks();
            } else {
                console.error('Error marking task as done');
            }
        } catch (error) {
            console.error('Error marking task as done:', error);
        }
    };

    const formatDateTime = (dateTimeString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        return new Date(dateTimeString).toLocaleString('no-NO', { timeZone: 'UTC' }, options).replace(',', ' -');
    };

    const getInputDateTimeString = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const pad = (number) => number.toString().padStart(2, '0');

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());

        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };

    const isExpired = (dateTimeString) => {
        return new Date(dateTimeString) < new Date();
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditProfileData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleProfilePictureChange = (e) => {
        setProfilePictureFile(e.target.files[0]);
    };

    const handleEditProfile = async () => {
        if (profilePictureFile) {
            const formData = new FormData();
            formData.append('profilePicture', profilePictureFile);
            formData.append('userId', usernameid); // Legg til userId i formdata

            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/upload-profile-picture`, {
                    method: 'POST',
                    body: formData,
                });
                const data = await response.json();
                setEditProfileData(prevState => ({ ...prevState, avatar: data.profilePictureUrl }));
            } catch (error) {
                console.error('Error uploading profile picture:', error);
            }
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/editable-profile/${usernameid}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(editProfileData),
            });
            if (response.ok) {
                fetchProfileData();
                setShowEditModal(false);
            } else {
                console.error('Error updating profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };



    const renderTasks = () => {
        const notDoneTasks = filteredTasks.filter(task => !task.done);
        const doneTasks = filteredTasks.filter(task => task.done);

        return (
            <div className="task-table-container">
                <table className="task-table">
                    <thead>
                        <tr>
                            <th>Vehicle</th>
                            <th>Info</th>
                            <th>Time</th>
                            <th>Customer</th>
                            <th>Done</th>
                        </tr>
                    </thead>
                    <tbody>
                        {notDoneTasks.map(task => (
                            <tr key={task.id} onClick={() => handleTaskClick(task)} className={isExpired(task.datetime) ? 'task-row-expired' : ''}>
                                <td>{task.vehicle}</td>
                                <td>{task.info}</td>
                                <td>{isExpired(task.datetime) ? '(expired, set new date or remove)' : formatDateTime(task.datetime)}</td>
                                <td>{task.customer}</td>
                                <td>
                                    <button onClick={(e) => { e.stopPropagation(); handleMarkAsDone(task.id); }}>Done</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <h2>Completed:</h2>
                <table className="task-table">
                    <thead>
                        <tr>
                            <th>Vehicle</th>
                            <th>Info</th>
                            <th>Time</th>
                            <th>Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {doneTasks.map(task => (
                            <tr key={task.id} className="task-row-done">
                                <td>{task.vehicle}</td>
                                <td>{task.info}</td>
                                <td>{formatDateTime(task.datetime)}</td>
                                <td>{task.customer}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {showTaskModal && (
                    <div className="taskmodal-background" onClick={() => setShowTaskModal(false)}>
                        <div className="taskmodal-content" onClick={e => e.stopPropagation()}>
                            <h3>Create New Task</h3>
                            <input
                                type="text"
                                name="vehicle"
                                placeholder="Vehicle"
                                value={newTask.vehicle}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="info"
                                placeholder="Info"
                                value={newTask.info}
                                onChange={handleInputChange}
                            />
                            <input
                                type="text"
                                name="customer"
                                placeholder="Customer"
                                value={newTask.customer}
                                onChange={handleInputChange}
                            />
                            <input
                                type="datetime-local"
                                name="datetime"
                                value={newTask.datetime}
                                onChange={handleInputChange}
                            />
                            <button onClick={handleCreateTask}>Create</button>
                        </div>
                    </div>
                )}

                {selectedTask && (
                    <div className="taskmodal-background" onClick={() => setSelectedTask(null)}>
                        <div className="taskmodal-content" onClick={e => e.stopPropagation()}>
                            <div className="task-input-group">
                                <label>Vehicle</label>
                                <input
                                    type="text"
                                    name="vehicle"
                                    placeholder="Vehicle"
                                    value={selectedTask.vehicle}
                                    onChange={handleModalInputChange}
                                />
                            </div>
                            <div className="task-input-group">
                                <label>Info</label>
                                <input
                                    type="text"
                                    name="info"
                                    placeholder="Info"
                                    value={selectedTask.info}
                                    onChange={handleModalInputChange}
                                />
                            </div>
                            <div className="task-input-group">
                                <label>Customer</label>
                                <input
                                    type="text"
                                    name="customer"
                                    placeholder="Customer"
                                    value={selectedTask.customer}
                                    onChange={handleModalInputChange}
                                />
                            </div>
                            <div className="task-input-group">
                                <label>Date and Time</label>
                                <input
                                    type="datetime-local"
                                    name="datetime"
                                    value={selectedTask.datetime}
                                    onChange={handleModalInputChange}
                                />
                            </div>
                            <button onClick={handleSaveTask}>Save</button>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderLogs = () => {
        return (
            <div className="logs-section">
                <div className="logs-container">
                    <table className="log-table">
                        <thead>
                            <tr>
                                <th>Log Text</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {logs.map(log => (
                                <tr key={log.id}>
                                    <td>{log.op_text}</td>
                                    <td>{new Date(log.created).toLocaleString('no-NO', { timeZone: 'UTC' })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };


    const renderCountryText = () => {
        switch (country) {
            case 'NO':
                return 'Her finner du oversikt over din avdeling, filer, rank og mulighet for å planlegge kunder og se aktivitet tilknyttet din konto. Rankingsystemet gir deg full oversikt over nåværende status, og hva som skal til for og nå neste nivå, samt hvilken plass du ligger på globalt sett. Customerlisten oppfordres til å bruke for alle planlagte tuninger, dette for at filservice skal få en god oversikt og indikasjon på når det kan forventes høyt trykk på filservice, slik at bemanning kan gjøres deretter. Dette resulterer i kortere turnaround for din egen del, samt en bedre flyt og raskere support ved behov.';
            case 'SE':
                return 'Här hittar du en översikt över din avdelning, filer, rank och möjligheten att planera kunder och se aktivitet kopplad till ditt konto. Rankningssystemet ger dig full översikt över nuvarande status och vad som krävs för att nå nästa nivå, samt vilken plats du har globalt sett. Kundlistan uppmuntras att användas för alla planerade tuningar, detta för att filservicen ska få en bra översikt och indikation på när högt tryck kan förväntas, så att bemanning kan göras därefter. Detta resulterar i kortare väntetider för din del, samt ett bättre flöde och snabbare support vid behov';
            default:
                return 'Here you will find an overview of your department, files, rank, and the ability to schedule customers and view activity associated with your account. The ranking system provides a complete overview of the current status, what is required to reach the next level, and your global standing. The customer list is encouraged to be used for all planned tunings to give the file service a good overview and indication of when high demand can be expected, allowing for staffing adjustments accordingly. This results in shorter turnaround times for you and better flow and faster support when needed';
        }
    };

    const processChartData = (data) => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        const groupedData = data.reduce((acc, { year, month, count }) => {
            if (!acc[year]) {
                acc[year] = Array(12).fill(null);
            }
            acc[year][month - 1] = count;
            return acc;
        }, {});

        const colors = {
            2024: 'rgba(255, 99, 132, 1)',
            2023: 'rgba(54, 162, 235, 1)',
            2022: 'rgba(75, 192, 192, 1)',
            2021: 'rgba(153, 102, 255, 1)',
            2020: 'rgba(255, 159, 64, 1)'
        };

        const datasets = Object.keys(groupedData).map(year => ({
            label: year,
            data: groupedData[year],
            borderColor: colors[year] || getRandomColor(),
            backgroundColor: (colors[year] || getRandomColor()).replace('1)', '0.5)'),
            spanGaps: true
        }));

        datasets.sort((a, b) => b.label - a.label);

        return {
            labels: months,
            datasets
        };
    };

    const getRandomColor = (opacity = 1) => {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };

    const getOptions = (title) => ({
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        return tooltipItems[0].label;
                    },
                    label: (tooltipItem) => {
                        const monthIndex = tooltipItem.dataIndex;
                        const chart = tooltipItem.chart;
                        const datasets = chart.data.datasets;

                        const uniqueTooltipData = new Map();

                        datasets.forEach(dataset => {
                            const year = dataset.label;
                            const value = dataset.data[monthIndex];
                            if (!uniqueTooltipData.has(year)) {
                                uniqueTooltipData.set(year, {
                                    year: year,
                                    value: value !== null ? value : 'null',
                                    color: dataset.borderColor
                                });
                            }
                        });

                        return Array.from(uniqueTooltipData.values())
                            .sort((a, b) => b.year - a.year)
                            .map(data => `<span style="color:${data.color}">${data.year}</span>: ${data.value}`);
                    },
                    labelColor: (tooltipItem) => {
                        return {
                            borderColor: tooltipItem.dataset.borderColor,
                            backgroundColor: tooltipItem.dataset.borderColor
                        };
                    }
                },
                usePointStyle: false,
                enabled: true,
                mode: 'index',
                intersect: false,
                itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
                callbacks: {
                    labelTextColor: function(tooltipItem) {
                        return tooltipItem.dataset.borderColor;
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    stepSize: 100
                }
            }
        }
    });

    const chartContainerStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '92%',
        height: '400px',
        margin: '0 auto',
        marginTop: '30px',
        backgroundColor: '#282828',
        padding: '10px',

    };

    const renderContent = () => {
        switch (activeSection) {
            case 'Overview':
                return (
                    <div className="overview-section">
                        <div className="stats-and-chart">
                            <div className="stats-container">
                            <div className="stat-box"><p>Your Rank: {userStats.rank}</p></div>
                                <div className="stat-box"><p>Files needed to rankup: {userStats.filesNeededToRankup}</p></div>
                                <div className="stat-box"><p>Files away from rankdown: {userStats.filesAwayFromRankdown}</p></div>
                                <div className="stat-box"><p>Total Files Today: {userStats.todayFiles}</p></div>
                                <div className="stat-box"><p>Total Files Weekly: {userStats.weeklyFiles}</p></div>
                                <div className="stat-box"><p>Total Files Monthly: {userStats.monthlyFiles}</p></div>
                                <div className="stat-box"><p>Total Files Yearly: {userStats.yearlyFiles}</p></div>
                                <div className="stat-box"><p>Total Files Ever: {userStats.totalFiles}</p></div>
                                <div className="stat-box"><p>Company Level: {companyLevel.level}</p></div>
                                <div className="stat-box"><p>Base credit: {companyLevel.baseCredits}</p></div>
                            </div>
                            <div className="chart-container">
                                    <div style={chartContainerStyle}>
                                        {uploadsData.length > 0 ? (
                                            <Line data={processChartData(uploadsData)} options={getOptions(`Uploads`)} />
                                        ) : (
                                            <p>Loading chart data...</p>
                                        )}
                                    </div>
                            </div>
                        </div>
                        <div className="ooh-contentholder">{renderCountryText()}</div>
                    </div>
                );
            case 'Tasks':
                return (
                    <div className="tasks-section">
                        <div className="tasks-sidebar">
                            <h3>Filter Customers</h3>
                            <ul>
                                <li onClick={() => setFilter('All')}><b>All</b></li>
                                <li onClick={() => setFilter('Today')}>Today</li>
                                <li onClick={() => setFilter('Week')}>Week</li>
                                <li onClick={() => setFilter('Month')}>Month</li>
                                <li onClick={() => setFilter('Year')}>Year</li>
                                <button onClick={() => setShowTaskModal(true)}>New Task</button>
                            </ul>
                        </div>
                        <div className="tasks-content">
                            {renderTasks()}
                        </div>
                    </div>
                );
            case 'Activity':
                    return renderLogs();
            case 'Shop':
                return (
                    <p>Her kommer webshoppen</p>
                );
            case 'Settings':
                return (
                    <p>Dette er settingssiden.. her ser hvis man er owner, oversikt over alle profiler som har tilgang på sitt firma, og kan kaste ut folk.</p>
                );
            default:
                return <p>Velg en seksjon.</p>;
        }
    };

    return (
        <div className="task-profilemodal-background" >
            <div className="task-profilemodal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <button className="close-btn" onClick={onClose}>CLOSE</button>
                </div>
                <div className="task-profile-header">
                    <div className="task-profile-left">
                        <div className="task-profile-picture">
                            <img src={avatar} alt="Profile" />
                        </div>
                        <div className="task-profile-info">
                            <p>Navn: {username}</p>
                            <p>Dealer: {profileData.name}</p>
                            <p>Company: {profileData.legal}</p>
                            <p>VAT: {profileData.vat}</p>
                            <p>Address: {profileData.address}</p>
                            <p>Employees: {profileData.employees.join(', ')}</p>

                            <button className="task-edit-profile-button" onClick={openEditModal}>Edit Profile</button>
                        </div>
                    </div>
                    <div className="hot-items">
                        <div className="hot-item">
                            <p>Hot Manufacturer</p>
                            <img src={`/images/manufacturers/${hotManufacturer}.png`} alt="Hot Manufacturer" />
                        </div>
                        <div className="hot-item">
                            <p>Hot Option</p>
                            <img src={`/images/options/${hotOption}.png`} alt="Hot Option" />
                        </div>
                        <div className="hot-item">
                            <p>Hot Tool</p>
                            <img src={`/images/tools/${hotTool}.png`} alt="Hot Tool" />
                        </div>
                    </div>
                </div>

                <div className="task-profile-nav">
                    <span onClick={() => setActiveSection('Overview')}>Overview</span>
                    <span onClick={() => setActiveSection('Tasks')}>Customers</span>
                    <span onClick={() => setActiveSection('Activity')}>Activity</span>
                    <span onClick={() => setActiveSection('Shop')}>Shop</span>
                     {/* <span onClick={() => setActiveSection('Settings')}>Settings</span> */}
                </div>

                <div className="task-profile-content">
                    {renderContent()}
                </div>

                {showEditModal && (
                    <div className="editprofilemodal-background" onClick={() => setShowEditModal(false)}>
                        <div className="editprofilemodal-content" onClick={e => e.stopPropagation()}>
                            <h3>Edit Profile</h3>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={editProfileData.name}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="text"
                                name="phone"
                                placeholder="Phone"
                                value={editProfileData.phone}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="text"
                                name="email"
                                placeholder="Email"
                                value={editProfileData.email}
                                onChange={handleEditInputChange}
                            />
                            <input
                                type="file"
                                name="profilePicture"
                                onChange={handleProfilePictureChange}
                            />
                            <button className="profile-button" onClick={handleEditProfile}>Save</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
