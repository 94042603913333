import React, { useState, useEffect, useCallback, useContext } from 'react';
import FileDetails from './FileDetails';
import { useAuth } from '../auth/AuthProvider';
import Profilesite from './Profilesite';
import Adminsite from './Adminsite';
import Oohsite from './Oohsite';
import Tossite from './Tossite';
import alert from '../assets/alert.gif';
import Chat from './Chat';
import { UserContext } from '../context/UserContext';


function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

function NewsModal({ article, articles = [], onSelectArticle, onClose }) {
  const articleContent = { __html: article?.body || 'Ingen innhold tilgjengelig' };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Escape') {
        onClose();
      }
    }

    if (article) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [article, onClose]);

  if (!article) return null;

  return (
    <div className="modal-background" onClick={onClose}>
      <div className="newsmodal-content" onClick={e => e.stopPropagation()}>
        <div className="news-menu">
          <ul>
            {articles.map((item, index) => (
              <li
                key={item.id}
                onClick={() => onSelectArticle(index)}
                className={article.id === item.id ? 'active' : ''}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
        <div className="news-details">
          <h2>{article.title}</h2>
          <p>{new Date(article.created).toLocaleDateString('no-NO', { timeZone: 'UTC' })}</p>
          <div dangerouslySetInnerHTML={articleContent}></div>
          <button onClick={onClose}>Lukk</button>
        </div>
      </div>
    </div>
  );
}



export default function FilePortal() {
  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => {
    setShowChat(!showChat);
  };
  const [vehicleData, setVehicleData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [newsArticles, setNewsArticles] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [selectedNews, setSelectedNews] = useState(null);
  const [showDropdown, setShowDropdown] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [showOohModal, setShowOohModal] = useState(false);
  const [showTosModal, setShowTosModal] = useState(false);
  const { authToken } = useAuth();
  const { userProfile, setUserProfile } = useContext(UserContext);
  const [overrideDates, setOverrideDates] = useState([]);

  useEffect(() => {
    const fetchOverrideDates = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SITE_URL + '/override-dates', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });
        const dates = await response.json();
        if (response.ok) {
          // Adjust for timezone differences
          const formattedDates = dates.map(date => {
            const localDate = new Date(date);
            return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
          });
          setOverrideDates(formattedDates);
        } else {
          console.error('Failed to fetch override dates:', dates.message);
        }
      } catch (error) {
        console.error('Error fetching override dates:', error);
      }
    };

    fetchOverrideDates();
  }, [authToken]);




  // const [userProfile, setUserProfile] = useState({
  //   id: '',
  //   name: '',
  //   legal: '',
  //   email: '',
  //   phone: '',
  //   vat: '',
  //   address: '',
  //   city: '',
  //   country: '',
  //   postal: '',
  //   admin: '',
  //   tuner: '',
  //   dealer: '',
  //   rank: '',
  //   level: '',
  //   registred_host: '',
  //   updated_ip: '',
  //   login_date: '',
  //   login_user_agent: '',
  //   opti: '',
  //   wl: '',
  //   approved: '',
  //   disabled: '',
  //   pid: '',
  //   did: '',
  //   companyName: '',
  //   companyRank: '',
  //   companyLevel: '',
  //   companyCredits: 0
  // });
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTuner, setIsTuner] = useState(false);
  const openProfileModal = () => setShowProfileModal(true);
  const closeProfileModal = () => setShowProfileModal(false);
  const openAdminModal = () => setShowAdminModal(true);
  const closeAdminModal = () => setShowAdminModal(false);
  const openOohModal = () => setShowOohModal(true);
  const closeOohModal = () => setShowOohModal(false);
  const openTosModal = () => setShowTosModal(true);
  const closeTosModal = () => setShowTosModal(false);
  const { logout } = useAuth();
  const [isSupport, setIsSupport] = useState(false);

  const toggleDropdown = (index) => {
    setShowDropdown(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  function handleLogout() {
    logout();
  }

  const handleRowClick = fileId => {
    setSelectedFileId(fileId);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const debouncedSearch = debounce(value => {
    setPage(1);
    setSearchText(value);
  }, 500);
  const [dealers, setDealers] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState('');
  const [wipOnly, setWipOnly] = useState(false);

  const fetchVehicleData = useCallback(async () => {
    let url = process.env.REACT_APP_SITE_URL + `/uploads?page=${page}&limit=500&searchText=${encodeURIComponent(searchText)}&wipOnly=${wipOnly}`;

    if (!isAdmin && userProfile.pid) {
      url += `&pid=${userProfile.pid}`;
    }

    if (isSupport) {
      url += `&support=true`;
    }

    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      }
    });

    const newData = await response.json();

    if (response.ok) {
      setVehicleData(prev => page === 1 ? newData : [...prev, ...newData]);
    } else {
      console.error("Failed to fetch vehicle data:", newData.message);
    }
  }, [page, searchText, wipOnly, authToken, isAdmin, isSupport, userProfile.pid]);

  useEffect(() => {
    fetchVehicleData();
  }, [fetchVehicleData]);

  useEffect(() => {
  }, [showDropdown]);

  useEffect(() => {
    const fetchDealers = async () => {
      const res = await fetch(process.env.REACT_APP_SITE_URL + '/dealers');
      const data = await res.json();
      setDealers(data);
    };
    fetchDealers();
  }, []);

  useEffect(() => {
    const fetchVehicleData = async () => {
      let url = process.env.REACT_APP_SITE_URL + `/uploads?page=${page}&limit=500&searchText=${encodeURIComponent(searchText)}`;
      if (selectedDealer) {
        url += `&dealerId=${selectedDealer}`;
      }
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      const newData = await response.json();
      setVehicleData(prev => page === 1 ? newData : [...prev, ...newData]);
    };
    fetchVehicleData();
  }, [page, searchText, selectedDealer]);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/news');
      const newsData = await response.json();
      setNewsArticles(newsData);
    };
    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNewsIndex(prevIndex => (prevIndex + 1) % newsArticles.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [newsArticles.length]);

  const handleDealerChange = e => {
    setSelectedDealer(e.target.value);
    setPage(1);
  };

  const getTuningTypeLabel = (tuningTypeId) => {
    const tuningTypes = {
      '1': { label: 'E', className: 'eco' },
      '3': { label: 'O', className: 'opti' },
      '4': { label: 'P', className: 'power' },
      '5': { label: 'S', className: 'stock' },
      '6': { label: 'X', className: 'extreme' }
    };

    return tuningTypes[tuningTypeId] || { label: 'Ukjent', className: '' };
  };

  const formatDateTime = (dateTimeString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateTimeString));
  };

  const sortedVehicleData = [...vehicleData].sort((a, b) => {
    const alertA = (isAdmin && a.tunercheck === 1) || (!isAdmin && a.dealercheck === 1);
    const alertB = (isAdmin && b.tunercheck === 1) || (!isAdmin && b.dealercheck === 1);

    if (a.status !== 2 && b.status === 2) return -1;
    if (a.status === 2 && b.status !== 2) return 1;
    if (alertA && !alertB) return -1;
    if (!alertA && alertB) return 1;
    return b.id - a.id;
  });

  const handleAction = async (index, action) => {
    let newStatus;
    switch (action) {
      case 'Sent':
        newStatus = 0;
        break;
      case 'In progress':
        newStatus = 1;
        break;
      case 'Hold':
        newStatus = 3;
        break;
      case 'Support':
        newStatus = 4;
        break;
      case 'Delete':
        return;
      case 'Credback':
        return;
      default:
        return;
    }

    await handleUpdateStatus(vehicleData[index].id, newStatus, isAdmin);

    setShowDropdown(prevState => ({
      ...prevState,
      [index]: false
    }));
  };

  const SvgAT = ({ color = '#FFA500' }) => (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="25" fill={color} />
      <text x="50%" y="55%" fontFamily="sans-serif" fontSize="20" fill="white" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">AT</text>
    </svg>
  );

  const SvgD = ({ color = '#ff000000' }) => (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="25" fill={color} />
      <text x="50%" y="55%" fontFamily="sans-serif" fontSize="25" fill="red" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">D</text>
    </svg>
  );

  const SvgT = ({ color = '#ff000000' }) => (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="25" fill={color} />
      <text x="50%" y="55%" fontFamily="sans-serif" fontSize="20" fill="blue" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">T</text>
    </svg>
  );

  const SvgM = ({ color = '#FFFFFF' }) => (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
      <rect width="25" height="25" fill={color} />
      <text x="50%" y="55%" fontFamily="sans-serif" fontSize="18" fill="red" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">M</text>
    </svg>
  );

  const getSvgForToolType = (tooltype) => {
    switch (tooltype) {
      case 1:
        return <SvgAT />;
      case 3:
        return <SvgD />;
      case 10:
        return <SvgT />;
      case 9:
        return <SvgM />;
      default:
        return null;
    }
  };

  const handleUpdateStatus = async (id, newStatus, isAdmin) => {
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + `/updateStatus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, status: newStatus, isAdmin }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchVehicleData();
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };


  function isOpen(serviceType) {
    const now = new Date();
    const day = now.getDay();
    const hour = now.getHours();
    const today = now.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    if (serviceType === 'AUTORESPONDER') {
      return true;
    }
    if (overrideDates.includes(today)) {
      return false;
    }
    if (serviceType === 'FILESERVICE') {
      if (day >= 1 && day <= 5 && hour >= 9 && hour < 21) {
        return true;
      } else if ((day === 6 || day === 0) && hour >= 10 && hour < 14) {
        return true;
      }
    }
    return false;
  }


  function timeUntilClosing(serviceType) {
    const now = new Date();
    const today = now.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    if (overrideDates.includes(today)) {
      return null;
    }

    let closingTime;

    if (serviceType === 'FILESERVICE') {
      const day = now.getDay();
      if (day >= 1 && day <= 5) {
        closingTime = new Date(now);
        closingTime.setHours(21, 0, 0, 0);
      } else if (day === 6 || day === 0) {
        closingTime = new Date(now);
        closingTime.setHours(14, 0, 0, 0);
      }
    } else {
      return null;
    }

    const diff = closingTime - now;
    if (diff <= 0) return null;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    if (hours < 2) {
      return `(${hours} hour${hours !== 1 ? 's' : ''}, ${minutes} minute${minutes !== 1 ? 's' : ''} until closing)`;
    }
    return null;
  }



  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SITE_URL + '/user-profile', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });
        if (response.ok) {
          const data = await response.json();
          setUserProfile(data);
          setIsAdmin(data.admin === 1 );
          setIsTuner(data.tuner === 1);
          setIsSupport(data.support === 1);
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, setUserProfile]);

  const [taskCountToday, setTaskCountToday] = useState(0);

  useEffect(() => {
    const fetchTaskCountToday = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SITE_URL + '/tasks/count-today', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          }
        });

        const data = await response.json();

        if (response.ok) {
          setTaskCountToday(data.count);
        } else {
          console.error('Failed to fetch task count. Server responded with:', data.message);
        }
      } catch (error) {
        console.error('Error fetching task count:', error);
      }
    };

    fetchTaskCountToday();
  }, [authToken]);


  const currentNewsArticle = newsArticles[currentNewsIndex];

  return (
    <div className="app-container">
      <div className="samlecontainer">



      </div>
      {selectedNews && (
      <NewsModal
        article={selectedNews}
        articles={newsArticles}
        onSelectArticle={(index) => setSelectedNews(newsArticles[index])}
        onClose={() => setSelectedNews(null)}
      />
    )}
      {showProfileModal && <Profilesite onClose={closeProfileModal} username={userProfile.name} usernameid={userProfile.id} did={userProfile.did} country={userProfile.country} />}
      {showAdminModal && <Adminsite onClose={closeAdminModal} id={userProfile.id} isAdmin={isAdmin} isTuner={isTuner}/>}
      {showOohModal && <Oohsite onClose={closeOohModal} did={userProfile.did} country={userProfile.country} />}
      {showTosModal && <Tossite onClose={closeTosModal} country={userProfile.country} />}
      {showModal && <FileDetails fileId={selectedFileId} onClose={closeModal} isAdmin={isAdmin} isTuner={isTuner} userProfile={userProfile} />}
      {/* <button onClick={toggleChat} className="chat-button">+</button> */}
      {/* {showChat && (
        <Chat
          onClose={toggleChat} // Sender onClose funksjonen til Chat komponenten
          userId={userProfile.id}
          isAdmin={userProfile.isAdmin}
          isTuner={userProfile.isTuner}
          did={userProfile.did}
        />
      )} */}



      <div className="app-files-section">
        <div className="app-search-and-filter-section">
          <div className="app-search-section">
            <input
              type="text"
              placeholder="Søk..."
              onChange={(e) => debouncedSearch(e.target.value)}
            />
          </div>
          {isAdmin && (
            <div className="app-filter-section">
              <select value={selectedDealer} onChange={handleDealerChange}>
                <option value="">All dealers</option>
                {dealers.map((dealer) => (
                  <option key={dealer.id} value={dealer.id}>{dealer.name}</option>
                ))}
              </select>
            </div>
          )}
          {/* <button className={`toggle-wip-button ${wipOnly ? 'active' : ''}`} onClick={() => setWipOnly(!wipOnly)}>Toggle WIP</button> */}
          {/* <div className="scroll-feed-container">
            <div className="scroll-feed">
              <p className="scroll-text-class">LAST UPLOADS: BMW X5 3.0d - Optituning avd Orkland      |      Toyota Proace: Optituning avd Trondheim      |      Audi A6 3.0d - Optituning avd Finnmark      |</p>
            </div>
          </div> */}
        </div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Vehicle {isAdmin && 'TEST'}</th>
              <th>Reg</th>
              {/* <th>Dealer</th> */}
              {/* <th></th> */}

              <th></th>

              {/* {isAdmin && (<th>Tuner</th>)}
              <th>Date</th> */}
              {/* <th>Charge</th> */}
              {/* {isAdmin && (<th>Tool</th>)} */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {sortedVehicleData.map((vehicle, index) => {
              const showAlert = (isAdmin && vehicle.tunercheck === 1) || (!isAdmin && vehicle.dealercheck === 1);

              return (
                <tr key={index} className={`status-${vehicle.status}`} onClick={() => handleRowClick(vehicle.id)}>
                  <td>
                    {showAlert && <b>{vehicle.id}</b>}
                    {!showAlert && vehicle.id}
                  </td>
                  <td className="truncate">{vehicle.vehicleDescription || 'Ukjent Kjøretøy'}</td>
                  <td>{vehicle.regnum}</td>

                  <td>
                    {showAlert ? (
                      <img src={alert} alt="Alert" className="alert" />
                    ) : null}
                  </td>

                  {/* <td>
  {vehicle.status === 0 && (
    <>
      <span className={`tuningType ${getTuningTypeLabel(vehicle.tuning_type).className}`}>
        {getTuningTypeLabel(vehicle.tuning_type).label}
      </span>
      {vehicle.egr !== 0 && <p className="file-option-small egr">E</p>}
      {vehicle.dpf !== 0 && <p className="file-option-small dpf">D</p>}
      {vehicle.adblue !== 0 && <p className="file-option-small adblue">A</p>}
      {vehicle.nsl !== 0 && <p className="file-option-small nsl">NSL</p>}
      {vehicle.o2 !== 0 && <p className="file-option-small o2">O2</p>}
      {vehicle.epa !== 0 && <p className="file-option-small epa">EPA</p>}
      {vehicle.hybridturbo !== 0 && <p className="file-option hybrid-turbo">HT</p>}
      {vehicle.gearbox !== 0 && <p className="file-option gearbox">GT</p>}
      {vehicle.coldstart !== 0 && <p className="file-option cold-start">CS</p>}
      {vehicle.startstop !== 0 && <p className="file-option start-stop">SS</p>}
      {vehicle.popsbangs !== 0 && <p className="file-option pops-bangs">PB</p>}
      {vehicle.burbles !== 0 && <p className="file-option burbles">BU</p>}
      {vehicle.swirlflaps !== 0 && <p className="file-option swirl-flaps">SW</p>}
      {vehicle.tva !== 0 && <p className="file-option tva">TVA</p>}
    </>
  )}
</td> */}






                  {/* {isAdmin && (<td>{vehicle.tuner}</td>)}
                  <td>{formatDateTime(vehicle.created)}</td>
                  <td>{vehicle.charged}</td>
                  {isAdmin && (
                    <td>{getSvgForToolType(vehicle.tooltype)}</td>
                  )} */}
                  <td>
                    <div className="app-action-buttons">
                      {(parseInt(vehicle.status) !== 2 || showAlert) && (
                        <button
                          className="app-action-button app-done-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUpdateStatus(vehicle.id, 2, isAdmin);
                          }}
                        >
                          D
                        </button>
                      )}
                      <div className="app-action-button app-more-button">
                        <button onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(index);
                        }}>...</button>
                        {showDropdown[index] && (
                          <div className="app-dropdown-content" onClick={(e) => e.stopPropagation()}>
                            <p onClick={() => handleAction(index, 'Sent')}>Sent</p>
                            <p onClick={() => handleAction(index, 'In progress')}>In progress</p>
                            <p onClick={() => handleAction(index, 'Hold')}>Hold</p>
                            <p onClick={() => handleAction(index, 'Support')}>Support</p>
                            <p onClick={() => handleAction(index, 'Delete')}>Delete</p>
                            <p onClick={() => handleAction(index, 'Credback')}>Creditback</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
