import React, { useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();
            if (response.ok) {
                login(data.token);
                navigate('/');  // Navigate to the homepage or desired component route
            } else {
                alert(data.message); // Show error message from server
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login failed, check console for details.');
        }
    };

    return (
        <div className='app-container'>
            <div className='app-login-container'>
                <div className='app-login-setup'>
                <img src={logo} alt="Logo" className="app-logo-login" />
                    <form onSubmit={handleSubmit}>
                        <h2>Login</h2>
                        <label>Email</label><br></br>
                        <input type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                        <p></p>
                        <label>Password</label>
                        <br></br>
                        <input type="password" value={password} onChange={e => setPassword(e.target.value)} required />
                        <br></br>
                        <button type="submit" className='app-login-button'>Login</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
