import React, { useEffect } from 'react';

export default function Tossite({ onClose, did }) {

    useEffect(() => {
    }, [did]);

    return (
        <div className="adminmodal-background">
            <div className="adminmodal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <button className="close-btn" onClick={onClose}>CLOSE</button>
                </div>
                <h2>TERMS OF SERVICE</h2>

                        <p>Støttekanaler:</p>
                        <ul>
                            <li>Telefonstøtte</li>
                            <li>Database / Support (Inneholder nyttig informasjon om kjøretøy + Supportbillett)</li>
                            <li>E-post (fileservice@optifiles.no)</li>
                            <li>Supportbilletter</li>
                        </ul>

                        <p>Åpningstider for Filservice:</p>
                        <ul>
                            <li>Man-Fre: 09:00-19:00 (CET/CEST)</li>
                            <li>Lørdag: 10:00-14:00 (CET/CEST)</li>
                            <li>Søndag: Stengt</li>
                            <li>* Begrenset service på kvelder/helger, men mulighet for booking av OOH Tuner</li>
                        </ul>

                        <h3>Tjenester:</h3>
                        <ul>
                            <li>Eco (God kraft og maksimal økonomi)</li>
                            <li>Opti (Hybrid & Kraft/Økonomi)</li>
                            <li>Power (Høy kraft og ultimat ytelse)</li>
                            <li>DPF løsninger og sletting av koder</li>
                            <li>EGR løsninger og sletting av koder</li>
                            <li>SCR / DEF / løsninger og koder</li>
                            <li>Alle typer MIL koder relatert til ECU</li>
                            <li>DSG remaps</li>
                            <li>Swirl/flapper løsninger/slettinger</li>
                            <li>VMAX av for bil og varebil</li>
                            <li>Bakre lambda / 02 sletting /dekat</li>
                            <li>Originale filer</li>
                            <li>Checksum for verktøy som ikke gjør dem</li>
                        </ul>
                        <p>Vi leverer vanligvis ikke crackles, pops & bangs, immo off løsninger, men i noen tilfeller kan vi tilby testløsninger med begrensede gjentakelser / support.</p>

                        <h3>Eco (God kraft og maksimal økonomi)</h3>
                        <p>Dette kan kun brukes på dieselbiler. Designet for å gi bedre økonomi i turbo dieselmotorer. Ved å justere kalibreringen av dreiemomentbegrensninger på motoren, vil du ved lavere RPM ha nytte av ekstra ytelse og forbedret økonomi. Ved høyere RPM vil programvaren oppmuntre til å skifte opp gjennom girkassen for å holde motoren i sitt optimale ytelsesvindu. Eco er et veldig populært valg blant store flåteeiere, hvor de ekstra drivstoffbesparelsene teller på driftskostnadene.</p>

                        <h3>Opti (Hybrid & Kraft/Økonomi)</h3>
                        <p>Dette kan kun brukes på dieselbiler. Det mest populære remap-valget, som tilbyr den beste blandingen av kraft og økonomi i praktisk talt alle turbo dieselmotorer. Designet for å fjerne begrensningene på motoren og åpne opp det ekte dreiemoment- og kraftområdet. Opti tilbyr en bedre kjøreopplevelse samtidig som den gir forbedret drivstofforbruk. Parametere administreres nøye for å optimalisere beste forbrenningstider og oppnå optimale inn-sylindertrykk.</p>

                        <h3>Power (Høy kraft og ultimat ytelse)</h3>
                        <p>Designet for å frigjøre det fulle potensialet i motoren din. Power kan installeres på både bensin- og dieselkjøretøy. Ideelt brukt i situasjoner der ekstra kraft og dreiemoment er nødvendig – tung belastning, optimalisert akselerasjon, bredere dreiemomentvindu, eller bare for å nyte kjøretøyet ditt.</p>

                        <h3>Ikke-tuning MIL koder</h3>
                        <p>Vi vil ikke avgjøre om en ikke-tuning MIL kode er trygg for kjøretøyet eller ikke. Som sertifisert forhandler og med kjøretøyet foran deg, er det din avgjørelse, eller hvis du ikke vet, gjør det rett og slett ikke.</p>
                        <p>Vær oppmerksom på at for eksempel en bil som produserer 50hk mindre på grunn av en ødelagt throttlebody, fortsatt vil produsere 50hk mindre selv når den er tunet, sammen med en kode sletting. Fjerning av ikke-tuning MIL koder er ikke en løsning for alt, så bruk det med omhu.</p>

                        <h3>DPF Filer</h3>
                        <p>Kjent for å ha problemer hvis DPF-tilbakestillinger ikke utføres med et diagnostisk verktøy før filen blir flashet. Den tunede filen endrer ikke overdrevent høye DPF live-data verdier som utløser advarselslys på dashbordet, tap av cruisekontroll, etc.</p>
                        <p>Det er ikke i remap-filen å tilbakestille disse verdiene, disse må gjøres med diagnostisk skanneverktøy før tunen skrives. Hvis det er et blinkende DPF-lys på dashbordet som original, betyr det at DPF-sotbelastningsinnholdet er høyt, tunen har ingen evne til å slå av det lyset og det vil forbli blinkende med tunefilen skrevet. Fysisk og diagnostisk arbeid er 90% av jobben, remap-filen er de siste 10%. Siden det finnes støttede kjøretøy på markedet, anbefaler vi at du sjekker om en programvareløsning er tilgjengelig før du gjør eller forteller kunden å fjerne DPF fysisk.</p>

                        <h3>EGR Filer</h3>
                        <p>Hvis Optifiles instruerer at EGR må kobles fra, MÅ den kobles fra, hvis det ikke står noe, må den forbli koblet til (det normale). Kjent for å ha problemer hvis EGR-ventilen er defekt, skadet, ødelagt eller fast. EGR-luftmasseberegninger samsvarer ikke med faktisk inntaksmanifoldvolum på grunn av at ventilen ikke kan svare på ECU-posisjonskravene. Hvis ECUen kommanderer EGR duty cycle til 0% og EGR-ventilen er fjernet og ligger på verkstedhyllen, vil flyten være feil. I tillegg utløses åpen krets MIL-koder og limp mode av manglende motstand tilbake til ECUen. En ny EGR eller rengjort EGR-ventil er ofte den eneste løsningen. Hvis en EGR-sletting er gjort på en bil der EGR-ventilen sitter fast i åpen stilling, kan en tetning også være nødvendig.</p>

                        <h3>SCR/DEF/ADBLUE FILES</h3>
                        <p>Hvis kjøretøyet viser "300 km igjen til motoren ikke starter", betyr dette at det lave AdBlue-varselet har blitt ignorert for lenge og at startsperren planlegger å stoppe ECUen fra å starte. Denne informasjonen er lagret i startsperredelen av ECUen/nøkkelfobben, og AdBlue-løsningen vil ikke fungere på dette tidspunktet, det er for sent. ECUen må tilbakestilles av det diagnostiske verktøyet for å bekrefte at AdBlue-tanken er fylt på nytt. Hvis AdBlue-systemet selv er defekt og ikke gjenkjenner tanknivået, vil de fleste kjøretøy utstyrt med AdBlue ha kalibreringsoppdateringer tilgjengelige fra hovedforhandlerne for å fikse AdBlue basisystemkalibreringsfeil, og hvis kjøretøyet fortsatt er under garanti, anbefales denne muligheten sterkt.</p>

                        <h3>Fil-Redo Politikk</h3>
                        <p>Ofte resulterer forsøk på å reparere et kjøretøy med flere problemer gjennom filen ofte i endeløse forsøk på å fikse det, med flere og flere koder som dukker opp. Alle redos vil være begrenset når det virker umulig og ikke løsbart ved fil. På det tidspunktet vil jobben bli ansett som avsluttet.</p>

                        <h3>Tilbakeføringsforespørsel for kreditt</h3>
                        <p>Hvis et kjøretøy settes tilbake til original innen 30 dager, kan du søke om tilbakeføringsforespørsel for kreditt. Hvis du av en eller annen grunn ikke er i stand til å sette det tilbake til original innen 30 dager, må du gi beskjed om dette i dialogen INNEN 30 dager. En kopi av loggen fra verktøyene må sendes til dialogen. (dette er ikke mulig med masterfiler).</p>

                        <h3>Laste opp filer</h3>
                        <p>Når du laster opp filer, prøv å fylle ut så riktig informasjon som mulig, noen kjøretøy kan mangle i rullegardinmenyene, i så fall, skriv så detaljerte notater som mulig.</p>

                        <h3>Dialog om fil / Tilbakemelding</h3>
                        <p>Dialogen brukes til all videre kommunikasjon angående det spesifikke kjøretøyet og alle reviderte filer, nye moduser, etc. skal alltid postes i dialogen for å holde all historikk på ett ID. Inne i hver fil-ID finner du dialogen angående denne filen, dette er nyttig å bruke når et "ikke akutt" problem eller tilbakemelding på fil er nødvendig. For eksempel etter at du har skrevet filen, og etter noen dager hører du fra kunden at tunen ikke var som forventet. Du kan her ta dialogen til tuneren og starte en samtale angående filen. Disse dialogene vil ha samme prioritet som en ny opplastet fil. Hvis du skulle motta tilbakemelding fra kunden, oppfordrer vi deg til å gi oss beskjed i denne dialogen.</p>

                        <h3>Revidert / Redo av fil</h3>
                        <p>Når du trenger en revidert fil, last alltid opp slavefilen som du har tenkt å få den reviderte filen for. Dette er for riktig kryptering/dekryptering formål, og vil spare tid i begge ender. En redo vil trekke tilbake kreditter hvis det er en endring av hva som opprinnelig ble lastet opp. Eller en endring av type tun etc. det vil koste ekstra etter 30 dager. Hvis behovet for en revidert fil er på grunn av en feil med filen, er filen alltid gratis, uten begrensning av dager. Hvis en bil har blitt satt tilbake til original hos forhandler/verksted, vil Optifiles gi en ny tun med 50% rabatt gitt de samme forholdene som originaltunene er gitt i redo, uten begrensning av dager. Det er ekstremt viktig at du tar en fersk lesing fra kjøretøyet og trykker på redo i opplastingen og legger inn kommentarer om dette. *Dette er ikke gyldig for masterfiler.</p>

                        <h3>Fil på vent</h3>
                        <p>Fra tid til annen kan det være flere grunner til at optifiles trenger tilbakemelding fra forhandleren før de fortsetter med filen. I disse tilfellene vil filen bli satt på vent, og gi en varsling i Optifiles. En melding fra tuneren vil følge i dialogen, og gir deg muligheten til å svare på situasjonen om nødvendig.</p>

                        <h3>KJØRETØYANSVAR</h3>
                        <p>Det er uunngåelig at det av og til vil gå galt, og når det skjer er prioriteten forhandlerens å tilfredsstille kunden, håndtere situasjonen og se den gjennom til en tilfredsstillende konklusjon. Hvis det er verktøyet som er feil, kan vi forvente at produsentene prøver å løse det, men vær oppmerksom på at de er svært usannsynlig å dekke noen kostnader. Selvfølgelig, på det svært sjeldne tilfellet det var vår feil, er det opp til oss å ordne det, men dette dekker ikke eventuelle følgetap, som leiebiler, etc. Teamet vårt vil gjøre alt vi kan for å hjelpe deg med å løse det, men forhandlere må være klar over at vi ikke garanterer dine feil eller verktøyfeil.</p>

                        <h3>OBD PROGRAMMERING - RISIKO</h3>
                        <p>På en daglig basis kan eventuelle problemer med OBD-programmering normalt løses, permanente feil er få og langt mellom. Når ting går galt, er det normalt en av følgende årsaker:</p>
                        <ul>
                            <li>Lav kjøretøybatterispenning eller manglende bruk av en batterilader</li>
                            <li>Slå av OBD-ledningen</li>
                            <li>Laptopbatteri, et virusskann, en skjermbeskytter som slår seg på, eller går i standby</li>
                            <li>Forårsaker en spenningspike ved å åpne og lukke døren</li>
                            <li>Verktøyprotokollfeil, eller defekt verktøy</li>
                            <li>Filfeil, som er sjelden og normalt vil føre til dårlig kjøring, snarere enn å ødelegge kjøretøyet</li>
                        </ul>

                        <h3>BENCH PROGRAMMERING AV ECU'S - RISIKO</h3>
                        <p>De fleste av disse jobbene går gjennom uten noen problemer overhodet, men det er flere potensielle farer enn en rett frem OBD-jobb, som kan resultere i en død ECU, disse inkluderer:</p>
                        <ul>
                            <li>Skade på ECU eller ledningsnett når du fjerner ECU</li>
                            <li>Skade på ECU når du fjerner lokket</li>
                            <li>Skade på ECU når loddetilkoblinger eller boot-ledninger (overoppheting av komponenter, loddesprut, etc)</li>
                            <li>Skade på ECU når du fjerner loddetilkoblinger eller boot-ledninger (overoppheting av komponenter, løfting av pads eller spor, loddesprut, etc)</li>
                            <li>Defekt eller korrupt fil (usannsynlig å forårsake en permanent situasjon)</li>
                            <li>Feil protokoller</li>
                            <li>Defekt verktøy eller skadede kontakter</li>
                        </ul>
                        <p>Tips - For å redusere programmeringsrisikoen, sjekk alltid og les verktøyprotokollinstruksjonene, selv om det er en kjøretøytype du har programmert før, da de blir oppdatert regelmessig.</p>
            </div>
        </div>
    );
}
