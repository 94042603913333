import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';

function Upload({ onClose }) {
  const { userProfile, setUserProfile } = useContext(UserContext);
  const { authToken } = useAuth();
  const [vehicleModels, setVehicleModels] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [selectedVehicleId, setSelectedVehicleId] = useState(null); // To store the selected vehicle's ID
  const [transmissionType, setTransmissionType] = useState('');
  const [toolType, setToolType] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [tuningType, setTuningType] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [range, setRange] = useState('');
  const [fuel_type, setFuelType] = useState('');
  const [model, setModel] = useState('');
  const [type, setType] = useState('');
  const [file, setFile] = useState(null);
  const [gearboxFile, setGearboxFile] = useState(null);
  const [miscFile, setMiscFile] = useState(null);
  const [priority, setPriority] = useState(1);
  const [comments, setComments] = useState('');
  const [egr, setEgr] = useState(0);
  const [dpf, setDpf] = useState(0);
  const [urea, setUrea] = useState(0);
  const [decat, setDecat] = useState(0);
  const [epa, setEpa] = useState(0);
  const [hybridturbo, setHybridturbo] = useState(0);
  const [gearbox, setGearbox] = useState(0);
  const [nsl, setNsl] = useState(0);
  const [coldstart, setColdstart] = useState(0);
  const [startstop, setStartstop] = useState(0);
  const [popsbangs, setPopsbangs] = useState(0);
  const [burbles, setBurbles] = useState(0);
  const [swirlflaps, setSwirlflaps] = useState(0);
  const [tva, setTva] = useState(0);
  const [nonstock, setNonstock] = useState(0);
  const [showFirstConfirmation, setShowFirstConfirmation] = useState(false);
  const [showSecondConfirmation, setShowSecondConfirmation] = useState(false);
  const [vehicleInfo, setVehicleInfo] = useState(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserProfile(data);
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, setUserProfile]);

  useEffect(() => {
    fetch(process.env.REACT_APP_SITE_URL + '/vehicle-models')
      .then(response => response.json())
      .then(data => {
        setVehicleModels(data);
      })
      .catch(error => {
        console.error('Error fetching vehicle models:', error);
      });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const handleFileOri = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleFileGearbox = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setGearboxFile(selectedFile);
    }
  };

  const handleFileMisc = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setMiscFile(selectedFile);
    }
  };

  const filteredModels = searchTerm
    ? vehicleModels.filter(model => {
      const fullModelString = `${model.manufacturerName} ${model.rangeName} ${model.modelName}`.toLowerCase();
      return searchTerm.toLowerCase().split(' ').every(part => fullModelString.includes(part));
    })
    : [];

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setShowSuggestions(value.trim().length > 0 && filteredModels.length > 0);
  };

  const selectVehicle = (model) => {
    setSelectedVehicle(`${model.manufacturerName} ${model.rangeName} ${model.modelName}`);
    setSelectedVehicleId(model.id);
    setManufacturer(model.manufacturerId);
    setRange(model.rangeId);
    setModel(model.id);
    setType(model.typeId)
    setFuelType(model.fueltype);
    setSearchTerm('');
    setShowSuggestions(false);
  };

  const handleCreateFileId = () => {
    const payload = {
      vehicleId: selectedVehicleId,
      transmissionType,
      toolType,
      fuel_type,
      tuningType,
      registrationNumber,
      priority,
      comments,
      egr,
      dpf,
      urea,
      decat,
      epa,
      hybridturbo,
      gearbox,
      nsl,
      coldstart,
      startstop,
      popsbangs,
      burbles,
      swirlflaps,
      tva,
      nonstock,
    };
    // Here you would send the payload to the server
    // fetch('http://localhost:5000/createFile', {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(payload)});
  };

  const fetchVehicleInfo = async (regNum) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
      if (!response.ok) {
        throw new Error('Failed to fetch vehicle data');
      }
      const data = await response.json();
      setVehicleInfo(data);
    } catch (error) {
      console.error('Error fetching vehicle data:', error);
    }
  };

  const handleSubmit = async () => {
    if (registrationNumber) {
      await fetchVehicleInfo(registrationNumber);
    }
    setShowFirstConfirmation(true);
  };

  const confirmFirstStep = () => {
    setShowFirstConfirmation(false);
    setShowSecondConfirmation(true);
  };

  const confirmSecondStep = async () => {
    const formData = new FormData();
    formData.append('username', userProfile.name);
    formData.append('mid', userProfile.did);
    formData.append('v_type', type);
    formData.append('orifile', file);
    formData.append('gearboxfile', gearboxFile);
    formData.append('miscfile', miscFile);
    formData.append('manufacturer', manufacturer);
    formData.append('range', range);
    formData.append('model', model);
    formData.append('tuning_type', tuningType);
    formData.append('regnum', registrationNumber);
    formData.append('tooltype', toolType);
    formData.append('fuel_type', fuel_type);
    formData.append('priority', priority);
    formData.append('comments', comments);
    formData.append('egr', egr);
    formData.append('dpf', dpf);
    formData.append('urea', urea);
    formData.append('decat', decat);
    formData.append('epa', epa);
    formData.append('hybridturbo', hybridturbo);
    formData.append('gearbox', gearbox);
    formData.append('nsl', nsl);
    formData.append('coldstart', coldstart);
    formData.append('startstop', startstop);
    formData.append('popsbangs', popsbangs);
    formData.append('burbles', burbles);
    formData.append('swirlflaps', swirlflaps);
    formData.append('tva', tva);
    formData.append('nonstock', nonstock);
    onClose(window.location.reload());


    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/uploadVehicleData', {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to upload file and data');
      }
      const result = await response.json();
    } catch (error) {
      console.error('Error uploading file and data:', error);
    }
  };


  const cancelFirstStep = () => {
    setShowFirstConfirmation(false);
  };

  const cancelSecondStep = () => {
    setShowSecondConfirmation(false);
  };


  useEffect(() => {
    setShowSuggestions(searchTerm.trim().length > 0 && filteredModels.length > 0);
  }, [searchTerm, filteredModels]);

  return (
    <div className="uploadmodal-background">
      <div className="uploadmodal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-btn" onClick={onClose}>CLOSE</button>
        </div>
        <div className="modal-body">
          <div className="left-section">
            <h3>Vehicle</h3>
            <div className="form-group">
              <input
                type="text"
                placeholder="Search vehicle type..."
                value={searchTerm}
                onChange={handleSearch}
                className="uploadsearch-input"
              />
              {showSuggestions && (
                <div className="uploadsearch-results">
                  {filteredModels.map(model => (
                    <div key={model.id} className="uploadsearch-result-item" onClick={() => selectVehicle(model)}>
                      {model.manufacturerName} {model.rangeName} {model.modelName}
                    </div>
                  ))}
                </div>
              )}
              <input
                type="text"
                value={selectedVehicle}
                placeholder="Selected vehicle type..."
                readOnly
              />
            </div>
            <select required value={transmissionType} onChange={(e) => setTransmissionType(e.target.value)}>
              <option value="">Select Transmission</option>
              <option value="2">Auto</option>
              <option value="4">DSG</option>
              <option value="3">Manual</option>
            </select>
            <input
              required
              type="text"
              value={registrationNumber}
              onChange={(e) => setRegistrationNumber(e.target.value)}
              placeholder="Registration Number"
            />

            <div className="customer-info">
              <h3>Customer Info</h3>
              <input placeholder="Customer Name" />
              <input placeholder="Address" />
              <input placeholder="Phone" />
              <input placeholder="Email" />
            </div>

          </div>
          <div className="right-section">
            <div className="form-group">
              <h3>Options</h3>
              <select value={toolType} onChange={(e) => setToolType(e.target.value)}>
                <option value="">Select Tool Type</option>
                <option value="1">Autotuner</option>
                <option value="2">MMFlex</option>
                <option value="3">NewGenius</option>
                <option value="10">Trasdata</option>
                <option value="12">K-Tag</option>
                <option value="11">Kess V3</option>
                <option value="9">Unlisted - Other tool</option>
              </select>
              <select value={tuningType} onChange={(e) => setTuningType(e.target.value)}>
                <option value="">Select Tuning Type</option>
                <option value="1">ECO - Fair Power, Maximum Economy</option>
                <option value="3">OPTI - Good Power, Improved Fuel Economy</option>
                <option value="4">POWER - High Power, Ultimate Performance</option>
                <option value="5">AS STOCK - No performance Increase</option>
              </select>

              <div className="checkbox-group">
                <label><input type="checkbox" name="egr" id="egr" value="1" /> EGR / AGR OFF (+2)</label><br />
                <label><input type="checkbox" name="dpf" id="dpf" value="1" /> DPF / OPF OFF (+2)</label><br />
                <label><input type="checkbox" name="urea" id="urea" value="1" /> UREA / AdBlue OFF (+5)</label><br />
                <label><input type="checkbox" name="decat" id="decat" value="1" /> DECAT / Rear O2 OFF (+2)</label><br />
                <label><input type="checkbox" name="epa" id="epa" value="1" /> EPA / Fixed VMAX (+7)</label><br />
                <label><input type="checkbox" name="hybridturbo" id="hybridturbo" value="1" /> HYBRIDTURBO (+7)</label><br />
                <label><input type="checkbox" name="gearbox" id="gearbox" value="1" /> Gearbox tune (+5)</label><br />
                <label><input type="checkbox" name="nsl" id="nsl" value="1" /> NSL / VMAX OFF (+2)</label><br />
                <label><input type="checkbox" name="coldstart" id="coldstart" value="1" /> Coldstart OFF (+2)</label><br />
                <label><input type="checkbox" name="startstop" id="startstop" value="1" /> Start/Stop Deactivation (+2)</label><br />
                <label><input type="checkbox" name="popsbangs" id="popsbang" value="1" /> Pops & Bangs (+5)</label><br />
                <label><input type="checkbox" name="burbles" id="burbles" value="1" /> Burbles (+5)</label><br />
                <label><input type="checkbox" name="swirlflaps" id="swirlflaps" value="1" /> Swirlflaps OFF (+2)</label><br />
                <label><input type="checkbox" name="tva" id="tva" value="1" /> TVA OFF (+2)</label><br />
                <label><input type="checkbox" name="nonstock" id="nonstock" value="1" /> File not stock</label><br />
              </div>
            </div>
            <div className="credits-info">
              <p>Your credits: 2020</p>
              <p>Estimated credits: 22</p>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label>Comments to file or vehicle</label>
          <textarea placeholder="Optional"></textarea><br />
              ORI
          <input type="file" onChange={handleFileOri} className="file-input" />
          GEARBOX
          <input type="file" onChange={handleFileGearbox} className="file-input" />
          MISC
          <input type="file" onChange={handleFileMisc} className="file-input" />
          <button onClick={handleSubmit} className="upload-btn">Upload File</button>


        </div>
      </div>
      {showFirstConfirmation && (
  <div className="modal-background">
    <div className="uploadconfirmation">
      <div className="modal-header">
        <button className="close-btn" onClick={() => cancelFirstStep()}>CLOSE</button>
      </div>
      {userProfile.country === 'NO' && (
        <>
          <p>Registreringsnummeret <b>{vehicleInfo.kjoretoydataListe[0]?.kjoretoyId?.kjennemerke}</b> du har angitt tilhører en:<b> {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke[0]?.merke} {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || "Ikke tilgjengelig"}</b> Bekreft at dette er riktig, eller avbryt for å endre</p>
          <button className="confirm-button" onClick={confirmFirstStep}>BEKREFT</button>
          <button className="decline-button" onClick={cancelFirstStep}>AVBRYT</button>
        </>
      )}
      {userProfile.country === 'SE' && (
        <>
          <p>Registreringsnumret <b>{vehicleInfo.kjoretoydataListe[0]?.kjoretoyId?.kjennemerke}</b> du har angivit tillhör en:<b> {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke[0]?.merke} {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || "Inte tillgänglig"}</b> Bekräfta att detta är korrekt, eller avbryt för att ändra</p>
          <button className="confirm-button" onClick={confirmFirstStep}>BEKRÄFTA</button>
          <button className="decline-button" onClick={cancelFirstStep}>AVBRYT</button>
        </>
      )}
      {!['NO', 'SE'].includes(userProfile.country) && (
        <>
          <p>The registration number <b>{vehicleInfo.kjoretoydataListe[0]?.kjoretoyId?.kjennemerke}</b> you have entered belongs to a:<b> {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.merke[0]?.merke} {vehicleInfo.kjoretoydataListe[0]?.godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse[0] || "Not available"}</b> Confirm that this is correct, or cancel to change</p>
          <button className="confirm-button" onClick={confirmFirstStep}>CONFIRM</button>
          <button className="decline-button" onClick={cancelFirstStep}>CANCEL</button>
        </>
      )}
    </div>
  </div>
)}

{showSecondConfirmation && (
  <div className="modal-background">
    <div className="uploadconfirmation">
      <div className="modal-header">
        <button className="close-btn" onClick={() => cancelSecondStep()}>CLOSE</button>
      </div>
      {userProfile.country === 'NO' && (
        <>
          <p>Bekreft at du har lagt ved alle relevante filer og evt. feilkoder. Samt valgt riktig kjøretøy, tilvalg og registreringsnummer</p>
          <button className="confirm-button" onClick={confirmSecondStep}>BEKREFT</button>
          <button className="decline-button" onClick={cancelSecondStep}>AVBRYT</button>
        </>
      )}
      {userProfile.country === 'SE' && (
        <>
          <p>Bekräfta att du har bifogat alla relevanta filer och eventuella felkoder. Samt valt rätt fordon, alternativ och registreringsnummer</p>
          <button className="confirm-button" onClick={confirmSecondStep}>BEKRÄFTA</button>
          <button className="decline-button" onClick={cancelSecondStep}>AVBRYT</button>
        </>
      )}
      {!['NO', 'SE'].includes(userProfile.country) && (
        <>
          <p>Confirm that you have attached all relevant files and any error codes. As well as selected the correct vehicle, options, and registration number</p>
          <button className="confirm-button" onClick={confirmSecondStep}>CONFIRM</button>
          <button className="decline-button" onClick={cancelSecondStep}>CANCEL</button>
        </>
      )}
    </div>
  </div>
)}

    </div>
  );
}

export default Upload;
