import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';

export default function Oohsite({ onClose, initialDid, country }) {
    const [requests, setRequests] = useState([]);
    const [days, setDays] = useState([]);
    const { authToken } = useAuth();
    const { userProfile, setUserProfile } = useContext(UserContext);
    const [did, setDid] = useState(initialDid);

    // Fetch user profile on mount or when authToken changes
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_SITE_URL + '/user-profile', {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserProfile(data);
                    setDid(data.did); // Update did from user profile
                } else {
                    throw new Error('Failed to fetch user profile');
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };

        if (authToken) {
            fetchUserProfile();
        }
    }, [authToken, setUserProfile]);

    useEffect(() => {
        generateDays();
        fetchOohRequests();
    }, [did, country]); // Note: This will re-run when did or country changes

    const generateDays = () => {
        const today = new Date();
        const daysArray = [];
        for (let i = 0; i < 14; i++) {
            const nextDay = new Date(today);
            nextDay.setDate(today.getDate() + i);
            daysArray.push(nextDay);
        }
        setDays(daysArray);
    };

    const fetchOohRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setRequests(data);
        } catch (error) {
            console.error('Error fetching ooh requests:', error);
        }
    };

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toISOString().split('T')[0]; // Format date as YYYY-MM-DD
    };

    const handleRequest = async (date) => {
        const formattedDate = formatDate(date);
        const payload = { did: did, date: formattedDate };

        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            const responseData = await response.json();
            if (response.ok) {
                fetchOohRequests();
            } else {
                console.error('Error creating ooh request:', responseData); // Log error message
            }
        } catch (error) {
            console.error('Error creating ooh request:', error);
        }
    };

    const renderCountryText = () => {
        switch (country) {
            case 'NO':
                return 'Dersom du har behov for filservice etter kl. 18:00, må du legge inn en forespørsel...';
            case 'SE':
                return 'Om du behöver filservice efter kl. 18:00, måste du lämna in en förfrågan...';
            default:
                return 'If you need file service after 6:00 PM, you must submit a request...';
        }
    };

    const renderContent = () => {
        return (
            <div className="admintasks-content">
                <h2 className='center-ooh'>Out of Hour Tuner Requests</h2>
                <div className="ooh-contentholder">{renderCountryText()}</div>
                <table className="admintasks-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Request</th>
                        </tr>
                    </thead>
                    <tbody>
                        {days.map(day => (
                            <tr key={day.toISOString()}>
                                <td>{day.toDateString()}</td>
                                <td>
                                    {requests.some(req => new Date(req.date).toDateString() === day.toDateString() && req.did === did) ? (
                                        requests.find(req => new Date(req.date).toDateString() === day.toDateString() && req.did === did && req.status === 1)
                                            ? `OOH Tuner: ${requests.find(req => new Date(req.date).toDateString() === day.toDateString() && req.did === did && req.status === 1).tuner_name}`
                                            : 'Request pending...'
                                    ) : (
                                        <button onClick={() => handleRequest(day)}>
                                            Request OOH Tuner
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div className="adminmodal-background" onClick={onClose}>
            <div className="adminmodal-content" onClick={e => e.stopPropagation()}>
                {renderContent()}
                {/* <button onClick={onClose}>Close</button> */}
            </div>
        </div>
    );
}
