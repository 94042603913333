import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../auth/AuthProvider';

const Chat = ({ onClose, userId, isAdmin, isTuner, did }) => {
  const [rooms, setRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const { authToken } = useAuth();
  const ws = useRef(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    fetchRooms();
  }, []);

  useEffect(() => {
    if (selectedRoom) {
      fetchMessages(selectedRoom.id);
      
      if (ws.current) {
        ws.current.close();
      }
      
      ws.current = new WebSocket(`ws://localhost:5000`);
      ws.current.onmessage = (event) => {
        try {
          const parsedMessage = JSON.parse(event.data);
          if (parsedMessage.type === 'message') {
            setMessages((prevMessages) => [...prevMessages, parsedMessage.data]);
            scrollToBottom();
          }
        } catch (error) {
          console.error('Error parsing message:', error);
        }
      };
    }

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [selectedRoom]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/chat/rooms?userId=${userId}&isAdmin=${isAdmin}&isTuner=${isTuner}&did=${did}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      setRooms(data);
      if (data.length > 0) {
        setSelectedRoom(data[0]);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  const fetchMessages = async (roomId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/chat/rooms/${roomId}/messages`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      setMessages(data.reverse());
      scrollToBottom();
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const sendMessage = async () => {
    try {
      const user_name = 'YourUsername'; // Hent det faktiske brukernavnet her
      const messageData = { message: newMessage, userId, user_name, roomId: selectedRoom.id };
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/chat/rooms/${selectedRoom.id}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(messageData),
      });
      if (response.ok) {
        ws.current.send(JSON.stringify({ type: 'message', data: messageData })); // Send message via WebSocket
        setMessages((prevMessages) => [...prevMessages, messageData]); // Update messages state immediately
        setNewMessage('');
        scrollToBottom();
      } else {
        console.error('Error sending message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="uploadmodal-background">
      <div className="chat-container">
        
        <div className="chat-header">
          <h3>Chat</h3>
          <div className="modal-header">
            <button className="close-btn" onClick={onClose}>CLOSE</button>
          </div>
        </div>
        <div className="chat-rooms">
          {rooms.map(room => (
            <div 
              key={room.id} 
              className={`chat-room ${selectedRoom && room.id === selectedRoom.id ? 'active' : ''}`}
              onClick={() => setSelectedRoom(room)}
            >
              {room.name}
            </div>
          ))}
        </div>
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index} className="chat-message">
              <span>{msg.user_name}</span>: {msg.message}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-input">
        <textarea
  value={newMessage}
  onChange={(e) => {
    setNewMessage(e.target.value);
    e.target.style.height = 'auto'; // Sett høyden til auto for å tillate dynamisk justering
    e.target.style.height = `${e.target.scrollHeight}px`; // Juster høyden til scrollHeight
  }}
  placeholder="Type your message..."
  style={{
    minHeight: '30px',
    height: 'auto',
    width: 'calc(100% - 20px)', // Dynamisk bredde basert på forelderens container
    resize: 'none',
    overflow: 'hidden',
    padding: '8px', // Juster padding etter behov
    boxSizing: 'border-box', // Sikre at padding er inkludert i bredden
  }}
/>






          <button onClick={sendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
